import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LeaderboardFacade } from 'libs/coach-bot/feature/leaderboards/src/lib/+state/leaderboard.facade';
import AuthenticationFacade from 'app/core/facades/authentication.facade';
import { STUDY_HABITS_ADMIN_NAV } from 'libs/coach-bot/ui/src/config/nav-dropdowns/student-habits-admin.nav';
import { TWO_X_LEARNING_ADMIN_NAV } from 'libs/coach-bot/ui/src/config/nav-dropdowns/twoLearningPopupAdmin.nav';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { ADMIN_LIST } from '../../config/nav-dropdowns/admin.config';
import { ANALYTICS_LIST } from '../../config/nav-dropdowns/analytics.nav';
import { HABITS_LIST } from '../../config/nav-dropdowns/habits.config';
import { LEADERBOARDS_LIST } from '../../config/nav-dropdowns/leaderboards.config';
import { REWARDS_LIST } from '../../config/nav-dropdowns/rewards.config';
import { MY_PROGRESS_LIST } from '../../config/nav-dropdowns/my-progress.config';
import { MenuItem } from '../../models/menu-item.model';
import { REPORTS_NAV } from '../../config/nav-dropdowns/reports.nav';
import { TWO_X_LEARNING_NAV } from '../../config/nav-dropdowns/two-x-learning.nav';
import { STUDY_HABITS_NAV } from '../../config/nav-dropdowns/study-habits.nav';
import { GAP_FILLING_NAV } from '../../config/nav-dropdowns/gap-filling.nav';

const MOST_CASH_EARNED_LEADERBOARD_KEY = 'mostCashEarned';
const TWO_X_MINUTES = '2xMinutes';

@Component({
  selector: 'cb-navigation',
  templateUrl: './coach-bot-navigation.component.html',
  styleUrls: ['./coach-bot-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoachBotNavigationComponent implements OnDestroy {
  @Input() public isAdmin = true;
  @Input() public isGuide = false;
  @Input() public isGuideOrAdmin = false;
  @Input() public isStudent = false;
  @Input() public isViewSkillsStackAccess = false;

  public adminList = ADMIN_LIST;
  public analyticsList = ANALYTICS_LIST;
  public studyHabitsListAdmin = STUDY_HABITS_ADMIN_NAV;
  public twoXLearningList = TWO_X_LEARNING_NAV;
  public twoXLearningListAdmin = TWO_X_LEARNING_ADMIN_NAV;
  public studyHabitsList = STUDY_HABITS_NAV;
  public gapFillingList = GAP_FILLING_NAV;

  public isAdminListActive = false;
  public isAnalyticsListActive = false;
  public isHabitsListActive = false;
  public isMyProgressActive = false;
  public isLeaderboardsListActive = false;
  public isRewardsListActive = false;
  public isMyProgressListActive = false;
  public isReportListActive = false;
  public isGapFillingListActive = false;
  public isTwoXLearningListActive = false;
  public isStudyHabitsListActive = false;

  public rewardsList = REWARDS_LIST;
  @Input() public studentId!: string;
  public visibleLeaderboards$ = this.leaderboardFacade.leaderboards$.pipe(
    map((leaderboards) => {
      if (this.isGuideOrAdmin) {
        return leaderboards;
      } else {
        return leaderboards.filter(
          (leaderboard) => leaderboard.key == MOST_CASH_EARNED_LEADERBOARD_KEY || leaderboard.key == TWO_X_MINUTES
        );
      }
    })
  );
  private destroy$ = new Subject();

  constructor(
    protected readonly _router: Router,
    private cdRef: ChangeDetectorRef,
    public leaderboardFacade: LeaderboardFacade,
    public authFacade: AuthenticationFacade
  ) {
    this.validateRoutes();
    this.onRouteChange();
    this.leaderboardFacade.init();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  /** checks if passed menu is the active one */
  private isMenuActive(menu: MenuItem[]): boolean {
    return menu.some((item) => {
      const link = item.link;
      return link === '/' ? link === this._router.url : link === this._router.url || this._router.url.includes(link);
    });
  }

  private onRouteChange() {
    this._router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((e) => e instanceof NavigationEnd),
        tap(() => {
          this.validateRoutes();
          this.cdRef.detectChanges();
        })
      )
      .subscribe();
  }

  private validateRoutes() {
    this.isAdminListActive = this.isMenuActive(ADMIN_LIST);
    this.isAnalyticsListActive = this.isMenuActive(ANALYTICS_LIST);
    this.isHabitsListActive = this.isMenuActive(HABITS_LIST);
    this.isMyProgressActive = this.isMenuActive(MY_PROGRESS_LIST);
    this.isLeaderboardsListActive = this.isMenuActive(LEADERBOARDS_LIST);
    this.isRewardsListActive = this.isMenuActive(REWARDS_LIST);
    this.isMyProgressListActive = this.isMenuActive(MY_PROGRESS_LIST);
    this.isReportListActive = this.isMenuActive(REPORTS_NAV);
    this.isGapFillingListActive = this.isMenuActive(GAP_FILLING_NAV);
    this.isTwoXLearningListActive = this.isMenuActive(TWO_X_LEARNING_NAV);
    this.isTwoXLearningListActive = this.isMenuActive(TWO_X_LEARNING_ADMIN_NAV);
    this.isStudyHabitsListActive = this.isMenuActive(STUDY_HABITS_NAV);
    this.isStudyHabitsListActive = this.isMenuActive(STUDY_HABITS_ADMIN_NAV);
  }
}
