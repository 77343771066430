export const linkLearningAppLevelToStandard = /* GraphQL */ `
  mutation LinkLearningAppLevelToStandard($input: LinkLearningAppLevelToStandardInput!) {
    linkLearningAppLevelToStandard(input: $input) {
		standardId
		learningResourceId
		type
	}
  }
`;
export const createIxlQuiz = /* GraphQL */ `
  mutation CreateIxlQuiz($studentId: String!, $standardExternalId: String!, $quizName: String!, $assignToStudent: Boolean) {
    createIxlQuiz(studentId: $studentId, standardExternalId: $standardExternalId, quizName: $quizName, assignToStudent: $assignToStudent) 
  }
`;
export const deleteDailyQuizzes = /* GraphQL */ `
  mutation DeleteDailyQuizzes($studentId: ID!) {
    deleteDailyQuizzes(studentId: $studentId) 
  }
`;
export const getOrCreateQuiz = /* GraphQL */ `
  mutation GetOrCreateQuiz($studentId: String, $externalStudentId: String, $appName: String!, $subject: String!, $numberOfQuestions: Int!, $quizName: String!, $selectionAlgorithm: String!, $scope: String, $assignToStudent: Boolean, $difficulty: CreateQuizDifficulty) {
    getOrCreateQuiz(studentId: $studentId, externalStudentId: $externalStudentId, appName: $appName, subject: $subject, numberOfQuestions: $numberOfQuestions, quizName: $quizName, selectionAlgorithm: $selectionAlgorithm, scope: $scope, assignToStudent: $assignToStudent, difficulty: $difficulty) {
		quizUrl
		status
	}
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
		id
		studentId
		notificationType
		title
		message
		isRead
		createdOn
	}
  }
`;
export const createCopilotNotification = /* GraphQL */ `
  mutation CreateCopilotNotification($input: CreateCopilotNotificationInput!) {
    createCopilotNotification(input: $input) {
		id
		studentEmail
		type
		message
		status
		createdOn
	}
  }
`;
export const setCopilotNotificationFeedback = /* GraphQL */ `
  mutation SetCopilotNotificationFeedback($input: SetCopilotNotificationFeedbackInput!) {
    setCopilotNotificationFeedback(input: $input) {
		notificationId
		studentFeedback
		studentFeedbackNotes
	}
  }
`;
export const markNotificationAsRead = /* GraphQL */ `
  mutation MarkNotificationAsRead($input: MarkNotificationAsRead!) {
    markNotificationAsRead(input: $input) {
		id
		studentId
		notificationType
		title
		message
		isRead
		createdOn
	}
  }
`;
export const updateLearningAppStatus = /* GraphQL */ `
  mutation UpdateLearningAppStatus($input: LearningAppStatusInput) {
    updateLearningAppStatus(input: $input) {
		id
		learningAppName
		learningAppImage
		status
		comment
	}
  }
`;
export const toggleSelfAssessedStatusForStandard = /* GraphQL */ `
  mutation ToggleSelfAssessedStatusForStandard($studentId: String!, $standardExternalId: String!, $selfAssessed: Boolean!) {
    toggleSelfAssessedStatusForStandard(studentId: $studentId, standardExternalId: $standardExternalId, selfAssessed: $selfAssessed) {
		selfAssessedStatus
	}
  }
`;
export const persistAppIntegration = /* GraphQL */ `
  mutation PersistAppIntegration($input: PersistAppIntegrationInput!) {
    persistAppIntegration(input: $input) {
		subscriptionId
		status
		message
	}
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($input: SendEmailInput) {
    sendEmail(input: $input) {
		email {
		mailFrom
		mailTo
		replyToEmail
		subject
		htmlMessage
		textMessage
	}
	}
  }
`;
export const publishLearningSignal = /* GraphQL */ `
  mutation PublishLearningSignal($input: PublishLearningSignalInput!) {
    publishLearningSignal(input: $input) {
		id
		type
		studentId
		learningAppLevelId
		additionalDetails
		source
		timestamp
		createdOn
		lastModifiedOn
	}
  }
`;
export const publishApplicationEvent = /* GraphQL */ `
  mutation PublishApplicationEvent($input: PublishLearningAppEventInput) {
    publishApplicationEvent(input: $input) {
		id
		clientLearningAppId
		targetLearningAppId
		learningAppEventTypeId
		studentId
		eventDate
		eventKey
		details
		subjectId
		grantedRewards {
		currency
		amount
	}
	}
  }
`;
export const postLearningAppMessage = /* GraphQL */ `
  mutation PostLearningAppMessage($input: PostLearningAppMessageInput) {
    postLearningAppMessage(input: $input) {
		id
		studentId
		learningAppId
		messageType
		payload
		createdOn
	}
  }
`;
export const createAntipattern = /* GraphQL */ `
  mutation CreateAntipattern($input: AntipatternInput!) {
    createAntipattern(input: $input) {
		id
		name
		category
		keyMetric
		severity
		description
		internalDescription
		generalCoaching
		implementation
		learningAppName
		extendedValues
		isActive
		createdOn
		lastModifiedOn
	}
  }
`;
export const updateAntipattern = /* GraphQL */ `
  mutation UpdateAntipattern($input: AntipatternInput!) {
    updateAntipattern(input: $input) {
		id
		name
		category
		keyMetric
		severity
		description
		internalDescription
		generalCoaching
		implementation
		learningAppName
		extendedValues
		isActive
		createdOn
		lastModifiedOn
	}
  }
`;
export const createAntipatternFinding = /* GraphQL */ `
  mutation CreateAntipatternFinding($input: AntipatternFindingInput!) {
    createAntipatternFinding(input: $input) {
		id
		antipatternName
		learningAppName
		implementation
		externalStudentId
		subjectName
		courseName
		startedOn
		endedOn
		findingDate
		extendedValues
		supportingEvidences
		specificCoaching
		internalNotes
		detectedBy
		studentFeedback
		studentFeedbackNotes
		timeWastedPercentage
		correctQuestionsImpactedPercentage
		correctQuestionsImpacted
		specificDescription
		createdOn
		status
		wastedMinutes
	}
  }
`;
export const updateAntipatternFinding = /* GraphQL */ `
  mutation UpdateAntipatternFinding($input: AntipatternFindingInput!) {
    updateAntipatternFinding(input: $input) {
		id
		antipatternName
		learningAppName
		implementation
		externalStudentId
		subjectName
		courseName
		startedOn
		endedOn
		findingDate
		extendedValues
		supportingEvidences
		specificCoaching
		internalNotes
		detectedBy
		studentFeedback
		studentFeedbackNotes
		timeWastedPercentage
		correctQuestionsImpactedPercentage
		correctQuestionsImpacted
		specificDescription
		createdOn
		status
		wastedMinutes
	}
  }
`;
export const updateAntipatternFindingStudentFeedback = /* GraphQL */ `
  mutation UpdateAntipatternFindingStudentFeedback($findingId: ID!, $studentFeedback: Boolean, $studentFeedbackNotes: String) {
    updateAntipatternFindingStudentFeedback(findingId: $findingId, studentFeedback: $studentFeedback, studentFeedbackNotes: $studentFeedbackNotes) 
  }
`;
export const markLearningAppMessagesAsViewed = /* GraphQL */ `
  mutation MarkLearningAppMessagesAsViewed($input: MarkLearningAppMessagesAsViewedInput!) {
    markLearningAppMessagesAsViewed(input: $input) {
		id
		platformStudentId
		learningAppName
		messageType
		payload
		viewed
		createdOn
		viewedOn
	}
  }
`;
export const upsertLearningSession = /* GraphQL */ `
  mutation UpsertLearningSession($input: UpsertLearningSessionInput!) {
    upsertLearningSession(input: $input) {
		platformLearningSessionId
	}
  }
`;
export const upsertLearningSessions = /* GraphQL */ `
  mutation UpsertLearningSessions($input: UpsertLearningSessionsInput!) {
    upsertLearningSessions(input: $input) {
		failedLearningSessions {
		url
		titlebar
		startTime
		endTime
	}
	}
  }
`;
export const createGeneratedContentComment = /* GraphQL */ `
  mutation CreateGeneratedContentComment($input: CreateGeneratedContentCommentInput!) {
    createGeneratedContentComment(input: $input) {
		commentId
	}
  }
`;
export const deleteGeneratedContentComment = /* GraphQL */ `
  mutation DeleteGeneratedContentComment($input: DeleteGeneratedContentCommentInput!) {
    deleteGeneratedContentComment(input: $input) {
		commentId
	}
  }
`;
export const updateGeneratedContentCommentFeedback = /* GraphQL */ `
  mutation UpdateGeneratedContentCommentFeedback($input: UpdateGeneratedContentCommentFeedbackInput!) {
    updateGeneratedContentCommentFeedback(input: $input) {
		commentFeedbackId
	}
  }
`;
export const generatedContentFeedback = /* GraphQL */ `
  mutation GeneratedContentFeedback($generatedContentId: ID!, $feedback: Boolean, $feedbackNotes: String, $feedbackType: GeneratedContentFeedbackType) {
    generatedContentFeedback(generatedContentId: $generatedContentId, feedback: $feedback, feedbackNotes: $feedbackNotes, feedbackType: $feedbackType) 
  }
`;
export const deleteGeneratedContentFeedback = /* GraphQL */ `
  mutation DeleteGeneratedContentFeedback($generatedContentId: ID!, $feedbackType: GeneratedContentFeedbackType!) {
    deleteGeneratedContentFeedback(generatedContentId: $generatedContentId, feedbackType: $feedbackType) 
  }
`;
export const recordGeneratedContentView = /* GraphQL */ `
  mutation RecordGeneratedContentView($input: RecordGeneratedContentViewInput!) {
    recordGeneratedContentView(input: $input) 
  }
`;
export const generateContentV2 = /* GraphQL */ `
  mutation GenerateContentV2($input: GenerateContentInput!) {
    generateContentV2(input: $input) {
		parentGeneratedContentId
		content
		generatedContentId
		contentTypeId
		contentTypeName
		contentTypeVersion
		grade
		subjectName
		platformSubjectId
		courseName
		platformCourseId
		domainId
		domainDescription
		platformDomainId
		clusterId
		clusterDescription
		platformClusterId
		standardId
		standardName
		standardDescription
		platformStandardId
		difficulty
		attributes {
		attributeName
		attributeValue
	}
		extendedAttributes {
		platformId
		value
		type
	}
		totalLikes
		totalViews
		totalComments
		totalBookmarks
		totalShares
		totalReports
		isLikedByUser
		isSharedByUser
		isBookmarkedByUser
		isCommentedByUser
		selectionReason
		warnings {
		code
		message
	}
	}
  }
`;
export const createGeneratedContentChunk = /* GraphQL */ `
  mutation CreateGeneratedContentChunk($input: CreateGeneratedContentChunkInput!) {
    createGeneratedContentChunk(input: $input) {
		userId
		requestId
		chunk
		contentSoFar
		createdOn
	}
  }
`;
export const insertContent = /* GraphQL */ `
  mutation InsertContent($input: InsertContentInput!) {
    insertContent(input: $input) {
		platformGeneratedContentId
	}
  }
`;
export const createPracticeSchedule = /* GraphQL */ `
  mutation CreatePracticeSchedule($input: PracticeScheduleInput!) {
    createPracticeSchedule(input: $input) {
		id
		studentId
		subjectId
		createdByUserId
		createdOn
		lastModifiedOn
		startDate
		status
		replacementDate
		measurement
		monday
		tuesday
		wednesday
		thursday
		friday
		saturday
		sunday
	}
  }
`;
export const stopPracticeSchedule = /* GraphQL */ `
  mutation StopPracticeSchedule($practiceScheduleId: String!) {
    stopPracticeSchedule(practiceScheduleId: $practiceScheduleId) {
		id
		studentId
		subjectId
		createdByUserId
		createdOn
		lastModifiedOn
		startDate
		status
		replacementDate
		measurement
		monday
		tuesday
		wednesday
		thursday
		friday
		saturday
		sunday
	}
  }
`;
export const createDailyGoalTrackingFeedback = /* GraphQL */ `
  mutation CreateDailyGoalTrackingFeedback($input: DailyGoalTrackingFeedbackInput!) {
    createDailyGoalTrackingFeedback(input: $input) {
		id
		dailyGoalTrackingId
		dailyGoalTrackingFeedbackReasonId
		description
	}
  }
`;
export const upsertLearningAppCourse = /* GraphQL */ `
  mutation UpsertLearningAppCourse($input: UpsertLearningAppCourseInput!) {
    upsertLearningAppCourse(input: $input) {
		id
	}
  }
`;
export const upsertLearningAppTopic = /* GraphQL */ `
  mutation UpsertLearningAppTopic($input: UpsertLearningAppTopicInput!) {
    upsertLearningAppTopic(input: $input) {
		id
	}
  }
`;
export const upsertLearningAppLevel = /* GraphQL */ `
  mutation UpsertLearningAppLevel($input: UpsertLearningAppLevelInput!) {
    upsertLearningAppLevel(input: $input) {
		id
	}
  }
`;
export const upsertLearningAppActivity = /* GraphQL */ `
  mutation UpsertLearningAppActivity($input: UpsertLearningAppActivityInput!) {
    upsertLearningAppActivity(input: $input) {
		topicMasteryId
		platformTopicMasteryId
		levelMasteryId
		platformLevelMasteryId
		questionId
		platformQuestionId
		isLevelMastered
		courseProgress {
		platformId
		courseId
		courseName
		masteryPercentage
		thresholdMasteryProgressPercentage
		masteredLevels
		totalLevels
		studentGrade
	}
		topicProgress {
		platformId
		topicId
		topicName
		masteryPercentage
		thresholdMasteryProgressPercentage
		masteredLevels
		totalLevels
		studentGrade
	}
		levelProgress {
		platformId
		levelId
		levelName
		masteredAt
		masteryPercentage
		thresholdMasteryProgressPercentage
		studentGrade
	}
	}
  }
`;
export const upsertLearningAppCourseEnrollment = /* GraphQL */ `
  mutation UpsertLearningAppCourseEnrollment($input: UpsertLearningAppCourseEnrollmentInput!) {
    upsertLearningAppCourseEnrollment(input: $input) {
		platformLearningAppCourseEnrollmentId
	}
  }
`;
export const upsertMasteryTest = /* GraphQL */ `
  mutation UpsertMasteryTest($input: UpsertMasteryTestInput!) {
    upsertMasteryTest(input: $input) {
		platformMasteryTestId
	}
  }
`;
export const upsertMasteryTestQuestion = /* GraphQL */ `
  mutation UpsertMasteryTestQuestion($input: UpsertMasteryTestQuestionInput!) {
    upsertMasteryTestQuestion(input: $input) {
		platformQuestionId
		rubricItems {
		name
		description
		maxScore
		platformStandardId
	}
	}
  }
`;
export const upsertMasteryTestQuestions = /* GraphQL */ `
  mutation UpsertMasteryTestQuestions($input: UpsertMasteryTestQuestionsInput!) {
    upsertMasteryTestQuestions(input: $input) {
		platformMasteryTestId
		masteryTestQuestions {
		id
		platformMasteryTestQuestionId
	}
	}
  }
`;
export const upsertQuestionMasteryTestMapping = /* GraphQL */ `
  mutation UpsertQuestionMasteryTestMapping($input: UpsertQuestionMasteryTestMappingInput!) {
    upsertQuestionMasteryTestMapping(input: $input) {
		platformQuestionId
		platformMasteryTestId
	}
  }
`;
export const upsertMasteryTestResult = /* GraphQL */ `
  mutation UpsertMasteryTestResult($input: UpsertMasteryTestResultInput!) {
    upsertMasteryTestResult(input: $input) {
		platformMasteryTestResultId
	}
  }
`;
export const upsertMasteryTestQuestionResult = /* GraphQL */ `
  mutation UpsertMasteryTestQuestionResult($input: UpsertMasteryTestQuestionResultInput!) {
    upsertMasteryTestQuestionResult(input: $input) {
		platformMasteryTestQuestionResultId
		isLevelMastered
	}
  }
`;
export const upsertMasteryTestStudentActivity = /* GraphQL */ `
  mutation UpsertMasteryTestStudentActivity($input: UpsertMasteryTestStudentActivityInput!) {
    upsertMasteryTestStudentActivity(input: $input) {
		platformMasteryTestId
		platformMasteryTestQuestionId
		platformMasteryTestResultId
		platformMasteryTestQuestionResultId
		levelMasteryId
		isLevelMastered
	}
  }
`;
export const upsertMasteryTestStudentActivities = /* GraphQL */ `
  mutation UpsertMasteryTestStudentActivities($input: UpsertMasteryTestStudentActivitiesInput!) {
    upsertMasteryTestStudentActivities(input: $input) {
		platformMasteryTestId
		platformMasteryTestResultId
		masteryTestQuestionResults {
		questionId
		platformMasteryTestQuestionId
		platformMasteryTestQuestionResultId
	}
	}
  }
`;
export const createCheckoutSession = /* GraphQL */ `
  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {
    createCheckoutSession(input: $input) {
		checkoutSessionId
		checkoutSessionUrl
	}
  }
`;
export const createStripePaymentIntent = /* GraphQL */ `
  mutation CreateStripePaymentIntent($input: CreateStripePaymentIntentInput!) {
    createStripePaymentIntent(input: $input) {
		requiresAction
		clientSecret
		success
		id
		nextAction {
		type
		redirect_to_url {
		url
		return_url
	}
	}
		error
		status
	}
  }
`;
export const manageStripePaymentIntent = /* GraphQL */ `
  mutation ManageStripePaymentIntent($input: ManageStripePaymentIntentInput!) {
    manageStripePaymentIntent(input: $input) {
		status
		amount
		currency
		metadata
		requiresAction
		clientSecret
		nextAction {
		type
		redirect_to_url {
		url
		return_url
	}
	}
		error
		type
		code
		declineCode
		param
	}
  }
`;
export const upsertStudentPlacementGrade = /* GraphQL */ `
  mutation UpsertStudentPlacementGrade($input: UpsertStudentPlacementGradeInput!) {
    upsertStudentPlacementGrade(input: $input) {
		platformStudentId
		placementGrade
	}
  }
`;
export const purchaseStoreItems = /* GraphQL */ `
  mutation PurchaseStoreItems($studentId: String, $cartItems: [CartItem], $learningAppName: String, $currency: String, $shippingDetails: ShippingDetailsInput, $metadata: AWSJSON) {
    purchaseStoreItems(studentId: $studentId, cartItems: $cartItems, learningAppName: $learningAppName, currency: $currency, shippingDetails: $shippingDetails, metadata: $metadata) {
		currencyTransaction {
		id
		studentId
		learningAppEventId
		studentOrderId
		amount
		currencyId
		externalKey
		transactionDetails
		createdOn
		lastModifiedOn
	}
		assetTransactions {
		id
		studentId
		learningAppEventId
		studentOrderId
		assetId
		amount
		externalKey
		transactionDetails
		createdOn
		lastModifiedOn
	}
	}
  }
`;
export const createStudentCashTransaction = /* GraphQL */ `
  mutation CreateStudentCashTransaction($input: CreateStudentCashTransactionInput!) {
    createStudentCashTransaction(input: $input) {
		createdUserName
		description
		amount
		createdOn
	}
  }
`;
export const createStudentCurrencyTransaction = /* GraphQL */ `
  mutation CreateStudentCurrencyTransaction($input: CreateStudentCurrencyTransactionInput!) {
    createStudentCurrencyTransaction(input: $input) {
		platformStudentCurrencyTransactionId
	}
  }
`;
export const initiateRefund = /* GraphQL */ `
  mutation InitiateRefund($input: InitiateRefundInput!) {
    initiateRefund(input: $input) {
		assetTransactions {
		id
		studentId
		learningAppEventId
		studentOrderId
		assetId
		amount
		externalKey
		transactionDetails
		createdOn
		lastModifiedOn
	}
		currencyTransactions {
		id
		studentId
		learningAppEventId
		studentOrderId
		amount
		currencyId
		externalKey
		transactionDetails
		createdOn
		lastModifiedOn
	}
	}
  }
`;
export const teachTapRedeemVoucher = /* GraphQL */ `
  mutation TeachTapRedeemVoucher($input: TeachTapRedeemVoucherInput!) {
    teachTapRedeemVoucher(input: $input) {
		success
		message
	}
  }
`;
export const teachTapSendUserEmail = /* GraphQL */ `
  mutation TeachTapSendUserEmail($input: TeachTapSendUserEmailInput!) {
    teachTapSendUserEmail(input: $input) {
		success
	}
  }
`;
export const teachTapAwardPoints = /* GraphQL */ `
  mutation TeachTapAwardPoints($input: TeachTapAwardPointsInput!) {
    teachTapAwardPoints(input: $input) {
		success
		pointsAwarded
		newTotalPoints
	}
  }
`;
export const teachTapUpdateUserProgress = /* GraphQL */ `
  mutation TeachTapUpdateUserProgress($input: TeachTapUpdateUserProgressInput!) {
    teachTapUpdateUserProgress(input: $input) {
		currentUnitId
		currentTopicId
		topicProgress {
		topicId
		studentGrade
		nextGradeProgress
	}
		unitProgress {
		unitId
		studentGrade
	}
	}
  }
`;
export const teachTapUpdateTargetedPracticeUserProgress = /* GraphQL */ `
  mutation TeachTapUpdateTargetedPracticeUserProgress($input: TeachTapUpdateTargetedPracticeUserProgressInput!) {
    teachTapUpdateTargetedPracticeUserProgress(input: $input) {
		targetedPracticeProgress
	}
  }
`;
export const upsertTeachTapUserSettings = /* GraphQL */ `
  mutation UpsertTeachTapUserSettings($input: UpsertTeachTapUserSettingsInput!) {
    upsertTeachTapUserSettings(input: $input) {
		userId
		goal
		examType
		grade
		emailOptIn
		examDate
		currentScore
		targetScore
		programType
		enrolledAt
		timezone
	}
  }
`;
export const teachTapGenerateLearningContentItem = /* GraphQL */ `
  mutation TeachTapGenerateLearningContentItem($input: TeachTapGenerateLearningContentItemInput!) {
    teachTapGenerateLearningContentItem(input: $input) {
		contentId
		contentTypeId
		contentTypeName
		content
		subjectId
		courseId
		unitId
		topicId
		levelId
		difficulty
		totalLikes
		totalViews
		totalComments
		totalBookmarks
		totalShares
		totalReports
		isLikedByUser
		isSharedByUser
		isBookmarkedByUser
		isCommentedByUser
	}
  }
`;
export const teachTapCreateUnitTest = /* GraphQL */ `
  mutation TeachTapCreateUnitTest($input: TeachTapCreateUnitTestInput!) {
    teachTapCreateUnitTest(input: $input) {
		courseId
		unitId
		testId
		questions {
		difficulty
		sequenceNumber
		contentTypeId
		questionId
		content
		contentId
		levelId
	}
		maxScore
		minScore
	}
  }
`;
export const teachTapGetLearningContentItems = /* GraphQL */ `
  mutation TeachTapGetLearningContentItems($input: TeachTapGetLearningContentItemsInput!) {
    teachTapGetLearningContentItems(input: $input) {
		contentId
		contentTypeId
		contentTypeName
		content
		subjectId
		courseId
		unitId
		topicId
		levelId
		difficulty
		totalLikes
		totalViews
		totalComments
		totalBookmarks
		totalShares
		totalReports
		isLikedByUser
		isSharedByUser
		isBookmarkedByUser
		isCommentedByUser
	}
  }
`;
export const teachTapGetTargetedPracticeLearningContentItems = /* GraphQL */ `
  mutation TeachTapGetTargetedPracticeLearningContentItems($input: TeachTapGetTargetedPracticeLearningContentItemsInput!) {
    teachTapGetTargetedPracticeLearningContentItems(input: $input) {
		contentId
		contentTypeId
		contentTypeName
		content
		subjectId
		courseId
		unitId
		topicId
		levelId
		difficulty
		totalLikes
		totalViews
		totalComments
		totalBookmarks
		totalShares
		totalReports
		isLikedByUser
		isSharedByUser
		isBookmarkedByUser
		isCommentedByUser
	}
  }
`;
export const teachTapRegisterPurchase = /* GraphQL */ `
  mutation TeachTapRegisterPurchase($input: TeachTapRegisterPurchaseInput!) {
    teachTapRegisterPurchase(input: $input) {
		success
	}
  }
`;
export const teachTapEnrollUserToCourse = /* GraphQL */ `
  mutation TeachTapEnrollUserToCourse($input: TeachTapEnrollUserToCourseInput!) {
    teachTapEnrollUserToCourse(input: $input) {
		success
	}
  }
`;
export const teachTapSubmitUnitTest = /* GraphQL */ `
  mutation TeachTapSubmitUnitTest($input: TeachTapSubmitUnitTestInput!) {
    teachTapSubmitUnitTest(input: $input) {
		courseId
		unitId
		grade
		pointsAwarded
		totalAwardedPoints
		questions {
		sequenceNumber
		questionId
		isCorrect
	}
	}
  }
`;
export const teachTapSubmitUnitTestQuestion = /* GraphQL */ `
  mutation TeachTapSubmitUnitTestQuestion($input: TeachTapSubmitUnitTestQuestionInput!) {
    teachTapSubmitUnitTestQuestion(input: $input) {
		success
	}
  }
`;
export const teachTapTrackUserEvent = /* GraphQL */ `
  mutation TeachTapTrackUserEvent($input: TeachTapTrackUserEventInput!) {
    teachTapTrackUserEvent(input: $input) {
		success
	}
  }
`;
export const teachTapSignUp = /* GraphQL */ `
  mutation TeachTapSignUp($input: TeachTapSignUpInput!) {
    teachTapSignUp(input: $input) {
		success
	}
  }
`;
export const teachTapUnenrollUserFromCourse = /* GraphQL */ `
  mutation TeachTapUnenrollUserFromCourse($input: TeachTapUnenrollUserFromCourseInput!) {
    teachTapUnenrollUserFromCourse(input: $input) {
		success
	}
  }
`;
export const registerDeviceForPushNotifications = /* GraphQL */ `
  mutation RegisterDeviceForPushNotifications($input: RegisterDeviceInput!) {
    registerDeviceForPushNotifications(input: $input) {
		endpointId
	}
  }
`;
export const unregisterDeviceForPushNotifications = /* GraphQL */ `
  mutation UnregisterDeviceForPushNotifications($input: UnregisterDeviceInput!) {
    unregisterDeviceForPushNotifications(input: $input) {
		success
	}
  }
`;
export const teachTapReportStudyTime = /* GraphQL */ `
  mutation TeachTapReportStudyTime($input: ReportStudyTimeInput!) {
    teachTapReportStudyTime(input: $input) {
		success
	}
  }
`;
export const teachTapSubmitAPAcademicData = /* GraphQL */ `
  mutation TeachTapSubmitAPAcademicData($input: TeachTapSubmitAPAcademicDataInput!) {
    teachTapSubmitAPAcademicData(input: $input) {
		success
	}
  }
`;
export const teachTapSubmitData = /* GraphQL */ `
  mutation TeachTapSubmitData($input: TeachTapSubmitDataInput!) {
    teachTapSubmitData(input: $input) {
		success
	}
  }
`;
export const teachTapUpdateUserProfileImage = /* GraphQL */ `
  mutation TeachTapUpdateUserProfileImage($input: TeachTapUpdateUserProfileImageInput!) {
    teachTapUpdateUserProfileImage(input: $input) {
		success
		message
	}
  }
`;
export const teachTapDeleteUserProfileImage = /* GraphQL */ `
  mutation TeachTapDeleteUserProfileImage {
    teachTapDeleteUserProfileImage {
		success
		message
	}
  }
`;
export const teachTapUpdateUserDetails = /* GraphQL */ `
  mutation TeachTapUpdateUserDetails($input: TeachTapUpdateUserDetailsInput!) {
    teachTapUpdateUserDetails(input: $input) {
		success
		message
	}
  }
`;
export const teachTapSubmitSATAcademicData = /* GraphQL */ `
  mutation TeachTapSubmitSATAcademicData($input: TeachTapSubmitSATAcademicDataInput!) {
    teachTapSubmitSATAcademicData(input: $input) {
		success
		message
	}
  }
`;
export const updateStudentGrade = /* GraphQL */ `
  mutation UpdateStudentGrade($input: UpdateStudentGradeInput!) {
    updateStudentGrade(input: $input) {
		platformStudentId
		gradeLevel
	}
  }
`;
export const signUp = /* GraphQL */ `
  mutation SignUp($firstName: String!, $lastName: String!, $email: AWSEmail, $password: String, $preferredName: String, $locale: String, $grade: String, $school: String, $studentId: String, $dateOfBirth: AWSDate, $gender: StudentGender, $learningLevel: String, $campusId: String, $roles: [UserEnrollmentRole!], $username: String, $referredByStudent: SignUpReferredByStudentIdentityInput, $emailOptIn: Boolean) {
    signUp(firstName: $firstName, lastName: $lastName, email: $email, password: $password, preferredName: $preferredName, locale: $locale, grade: $grade, school: $school, studentId: $studentId, dateOfBirth: $dateOfBirth, gender: $gender, learningLevel: $learningLevel, campusId: $campusId, roles: $roles, username: $username, referredByStudent: $referredByStudent, emailOptIn: $emailOptIn) 
  }
`;
export const signUpV2 = /* GraphQL */ `
  mutation SignUpV2($input: SignUpInput!) {
    signUpV2(input: $input) {
		status
		userId
		verificationCode
	}
  }
`;
export const enrollStudentInProgram = /* GraphQL */ `
  mutation EnrollStudentInProgram($studentId: String, $platformStudentId: String, $email: String, $platformSchoolId: String, $schoolStudentId: String, $program: String, $voucher: String, $startCourseName: String, $endCourseName: String, $subject: String, $enrollmentStartDate: String) {
    enrollStudentInProgram(studentId: $studentId, platformStudentId: $platformStudentId, email: $email, platformSchoolId: $platformSchoolId, schoolStudentId: $schoolStudentId, program: $program, voucher: $voucher, startCourseName: $startCourseName, endCourseName: $endCourseName, subject: $subject, enrollmentStartDate: $enrollmentStartDate) {
		studentId
		programId
		startDate
		endDate
	}
  }
`;
export const updateStudentProgramEnrollment = /* GraphQL */ `
  mutation UpdateStudentProgramEnrollment($input: UpdateStudentProgramEnrollmentInput!) {
    updateStudentProgramEnrollment(input: $input) {
		programEnrollmentId
		programId
		programKey
		startDate
		endDate
		startCourseName
		endCourseName
		subjectName
		isUpdateAllowed
		purchaseSource
	}
  }
`;
export const softDeleteUser = /* GraphQL */ `
  mutation SoftDeleteUser($input: SoftDeleteUserInput) {
    softDeleteUser(input: $input) {
		userId
	}
  }
`;
export const storeStudentThirdPartyAppCredentials = /* GraphQL */ `
  mutation StoreStudentThirdPartyAppCredentials($input: ThirdPartyAppCredentialsInput!) {
    storeStudentThirdPartyAppCredentials(input: $input) 
  }
`;
export const validateVoucher = /* GraphQL */ `
  mutation ValidateVoucher($voucher: String!, $program: String) {
    validateVoucher(voucher: $voucher, program: $program) {
		valid
	}
  }
`;
export const updateUserDetails = /* GraphQL */ `
  mutation UpdateUserDetails($input: UpdateUserDetailsInput!) {
    updateUserDetails(input: $input) {
		id
		firstName
		lastName
		preferredName
		email
		roleName
		roles
		locale
		profileImageURL
		coverImageURL
		acceptedTerms
		permissions
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
		agents {
		id
		firstName
		lastName
		email
		preferredName
		roles
		platformStudentId
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
	}
		platformStudentId
		referredUserIds
		emailOptIn
		isAnonymous
	}
  }
`;
export const updateUserTerm = /* GraphQL */ `
  mutation UpdateUserTerm($userDetails: UpdateUserTermInput!) {
    updateUserTerm(userDetails: $userDetails) {
		id
		firstName
		lastName
		preferredName
		email
		roleName
		roles
		locale
		profileImageURL
		coverImageURL
		acceptedTerms
		permissions
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
		agents {
		id
		firstName
		lastName
		email
		preferredName
		roles
		platformStudentId
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
	}
		platformStudentId
		referredUserIds
		emailOptIn
		isAnonymous
	}
  }
`;
export const updateUserProfileImage = /* GraphQL */ `
  mutation UpdateUserProfileImage($userDetails: UpdateUserProfileImageInput!) {
    updateUserProfileImage(userDetails: $userDetails) {
		id
		firstName
		lastName
		preferredName
		email
		roleName
		roles
		locale
		profileImageURL
		coverImageURL
		acceptedTerms
		permissions
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
		agents {
		id
		firstName
		lastName
		email
		preferredName
		roles
		platformStudentId
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
	}
		platformStudentId
		referredUserIds
		emailOptIn
		isAnonymous
	}
  }
`;
export const updateUserCoverImage = /* GraphQL */ `
  mutation UpdateUserCoverImage($userDetails: UpdateUserCoverImageInput!) {
    updateUserCoverImage(userDetails: $userDetails) {
		id
		firstName
		lastName
		preferredName
		email
		roleName
		roles
		locale
		profileImageURL
		coverImageURL
		acceptedTerms
		permissions
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
		agents {
		id
		firstName
		lastName
		email
		preferredName
		roles
		platformStudentId
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
	}
		platformStudentId
		referredUserIds
		emailOptIn
		isAnonymous
	}
  }
`;
export const addStudentProfileStudentAttributes = /* GraphQL */ `
  mutation AddStudentProfileStudentAttributes($studentId: ID!, $attributeIds: [ID!]!) {
    addStudentProfileStudentAttributes(studentId: $studentId, attributeIds: $attributeIds) {
		attributeType
		attributes {
		id
		value
	}
	}
  }
`;
export const removeStudentProfileStudentAttributes = /* GraphQL */ `
  mutation RemoveStudentProfileStudentAttributes($studentId: ID!, $attributeIds: [ID!]!) {
    removeStudentProfileStudentAttributes(studentId: $studentId, attributeIds: $attributeIds) {
		attributeType
		attributes {
		id
		value
	}
	}
  }
`;
export const createEnrollmentVoucher = /* GraphQL */ `
  mutation CreateEnrollmentVoucher($input: CreateEnrollmentVoucherInput!) {
    createEnrollmentVoucher(input: $input) {
		voucherCode
		studentExists
		isEnrolled
		startDate
		endDate
	}
  }
`;
export const provisionAnonymousUser = /* GraphQL */ `
  mutation ProvisionAnonymousUser($input: ProvisionAnonymousUserInput!) {
    provisionAnonymousUser(input: $input) {
		email
		password
	}
  }
`;
export const claimAnonymousUser = /* GraphQL */ `
  mutation ClaimAnonymousUser($input: ClaimAnonymousUserInput!) {
    claimAnonymousUser(input: $input) {
		userId
		message
	}
  }
`;
export const deleteUserProfileImage = /* GraphQL */ `
  mutation DeleteUserProfileImage($input: DeleteUserProfileImageInput!) {
    deleteUserProfileImage(input: $input) {
		id
		firstName
		lastName
		preferredName
		email
		roleName
		roles
		locale
		profileImageURL
		coverImageURL
		acceptedTerms
		permissions
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
		agents {
		id
		firstName
		lastName
		email
		preferredName
		roles
		platformStudentId
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
	}
		platformStudentId
		referredUserIds
		emailOptIn
		isAnonymous
	}
  }
`;
export const createReferral = /* GraphQL */ `
  mutation CreateReferral($input: CreateReferralInput!) {
    createReferral(input: $input) {
		referredByStudentRewards {
		currencyRewards {
		amount
		currency
	}
	}
		enrolledStudentRewards {
		currencyRewards {
		amount
		currency
	}
	}
	}
  }
`;
export const upsertExtendedStudentAttributes = /* GraphQL */ `
  mutation UpsertExtendedStudentAttributes($input: UpsertExtendedStudentAttributesInput!) {
    upsertExtendedStudentAttributes(input: $input) {
		attributes {
		attributeKey
		attributeValue
	}
	}
  }
`;
export const getUserEnrollmentVerificationCode = /* GraphQL */ `
  mutation GetUserEnrollmentVerificationCode($input: GetUserEnrollmentVerificationCodeInput!) {
    getUserEnrollmentVerificationCode(input: $input) {
		verificationCode
	}
  }
`;
export const verifyUserEnrollment = /* GraphQL */ `
  mutation VerifyUserEnrollment($input: VerifyUserEnrollmentInput!) {
    verifyUserEnrollment(input: $input) {
		verified
	}
  }
`;