<ul class="list" *ngIf="!isAdmin">
  <li class="list-item">
    <button #reportsMenu [class.-active]="isReportListActive" class="nav-button" kendoButton>Reports</button>
    <kendo-contextmenu #reportsPopup [alignToAnchor]="true" [target]="reportsMenu" showOn="click">
      <ng-template kendoContextMenuTemplate>
        <div
          [style.width.px]="390"
          (click)="reportsPopup.hide()"
          routerLink="/knowledge-assessment&gap-filling-actions"
          class="k-p-3 k-cursor-pointer"
          routerLinkActive="-active"
          [class.disabled]="authFacade.noFullReportView$ | async">
          Knowledge Assessment & Gap-Filling Actions
        </div>
      </ng-template>
    </kendo-contextmenu>
  </li>

  <li class="list-item">
    <button class="nav-button" kendoButton routerLink="/rewards" routerLinkActive="-active">Rewards</button>
  </li>
</ul>

<ul class="list" *ngIf="isAdmin">
  <li class="list-item">
    <button #reportsMenu [class.-active]="isReportListActive" class="nav-button" kendoButton>Reports</button>
    <kendo-contextmenu #reportsPopup [alignToAnchor]="true" [target]="reportsMenu" showOn="click">
      <ng-template kendoContextMenuTemplate>
        <div
          *ngIf="authFacade.isStudent$ | async"
          [style.width.px]="390"
          (click)="reportsPopup.hide()"
          routerLink="/math-skills-stack"
          class="k-p-3 k-cursor-pointer"
          routerLinkActive="-active"
          [class.disabled]="authFacade.noViewSkillsStackAccess$ | async">
          Math Skill Stack (2D)
        </div>
        <div
          [style.width.px]="390"
          (click)="reportsPopup.hide()"
          routerLink="/covid-learning-loss-report-math"
          class="k-p-3 k-cursor-pointer"
          routerLinkActive="-active"
          [class.disabled]="authFacade.noCovidLearningLossAccess$ | async">
          Knowledge Assessment - Math stack
        </div>
        <div
          [style.width.px]="390"
          (click)="reportsPopup.hide()"
          routerLink="/knowledge-assessment&gap-filling-actions"
          class="k-p-3 k-cursor-pointer"
          routerLinkActive="-active"
          [class.disabled]="authFacade.noFullReportView$ | async">
          Knowledge Assessment & Gap-Filling Actions
        </div>
        <div
          [style.width.px]="390"
          (click)="reportsPopup.hide()"
          routerLink="/map-summary"
          class="k-p-3 k-cursor-pointer"
          routerLinkActive="-active">
          National Percentile Rank
        </div>
      </ng-template>
    </kendo-contextmenu>
  </li>

  <!-- Gap Filling -->
  <li *ngIf="isGuideOrAdmin" class="list-item">
    <button #gapFeeling [class.-active]="isGapFillingListActive" class="nav-button" kendoButton>
      Gap Filling
    </button>
    <kendo-contextmenu #gapFillingPopup [alignToAnchor]="true" [target]="gapFeeling" showOn="click">
      <ng-template kendoContextMenuTemplate>
        <ng-container *ngFor="let item of gapFillingList">
          <div
            (click)="gapFillingPopup.hide()"
            [style.width.px]="211"
            [hidden]="item.hide"
            [routerLink]="item.link"
            class="k-p-3 k-cursor-pointer"
            routerLinkActive="-active">
            {{ item.title }}
          </div>
        </ng-container>
      </ng-template>
    </kendo-contextmenu>
  </li>

  <!-- 2X Learning Student -->
  <li class="list-item" *ngIf="isStudent">
    <button #twoXLearningMenu [class.-active]="isTwoXLearningListActive" class="nav-button" kendoButton>
      2X Learning
    </button>
    <kendo-contextmenu #twoXLearningPopup [alignToAnchor]="true" [target]="twoXLearningMenu" showOn="click">
      <ng-template kendoContextMenuTemplate>
        <ng-container *ngFor="let item of twoXLearningList">
          <div
            [style.width.px]="211"
            (click)="twoXLearningPopup.hide()"
            [hidden]="item.hide"
            [routerLink]="item.link"
            class="k-p-3 k-cursor-pointer"
            routerLinkActive="-active">
            {{ item.title }}
          </div>
        </ng-container>
      </ng-template>
    </kendo-contextmenu>
  </li>

  <!-- 2X Learning Admin/Guide -->
  <li class="list-item" *ngIf="isGuideOrAdmin">
    <button #twoXLearningAdminMenu [class.-active]="isTwoXLearningListActive" class="nav-button" kendoButton>
      2X Learning
    </button>
    <kendo-contextmenu #twoXLearningAdminPopup [alignToAnchor]="true" [target]="twoXLearningAdminMenu" showOn="click">
      <ng-template kendoContextMenuTemplate>
        <ng-container *ngFor="let item of twoXLearningListAdmin">
          <div
            [style.width.px]="211"
            (click)="twoXLearningAdminPopup.hide()"
            [hidden]="item.hide"
            [routerLink]="item.link"
            class="k-p-3 k-cursor-pointer"
            routerLinkActive="-active">
            {{ item.title }}
          </div>
        </ng-container>
      </ng-template>
    </kendo-contextmenu>
  </li>

  <!-- Study Habits Student -->
  <li *ngIf="isStudent" class="list-item">
    <button #studyHabitsMenu [class.-active]="isStudyHabitsListActive" class="nav-button" kendoButton>
      Study Habits
    </button>
    <kendo-contextmenu #studyHabitsPopup [alignToAnchor]="true" [target]="studyHabitsMenu" showOn="click">
      <ng-template kendoContextMenuTemplate>
        <ng-container *ngFor="let item of studyHabitsList">
          <div
            [style.width.px]="211"
            (click)="studyHabitsPopup.hide()"
            [hidden]="item.hide"
            [routerLink]="item.link"
            class="k-p-3 k-cursor-pointer"
            routerLinkActive="-active">
            {{ item.title }}
          </div>
        </ng-container>
      </ng-template>
    </kendo-contextmenu>
  </li>

  <!-- Study Habits Admin/Guide -->
  <li *ngIf="isGuideOrAdmin" class="list-item">
    <button #studyHabitsAdminMenu [class.-active]="isStudyHabitsListActive" class="nav-button" kendoButton>
      Study Habits
    </button>
    <kendo-contextmenu #studyHabitsAdminPopup [alignToAnchor]="true" [target]="studyHabitsAdminMenu" showOn="click">
      <ng-template kendoContextMenuTemplate>
        <ng-container *ngFor="let item of studyHabitsListAdmin">
          <div
            [style.width.px]="211"
            (click)="studyHabitsAdminPopup.hide()"
            [hidden]="item.hide"
            [routerLink]="item.link"
            class="k-p-3 k-cursor-pointer"
            routerLinkActive="-active">
            {{ item.title }}
          </div>
        </ng-container>
      </ng-template>
    </kendo-contextmenu>
  </li>

  <!-- Rewards Student -->
  <li class="list-item">
    <button class="nav-button" kendoButton routerLink="/rewards" routerLinkActive="-active">Rewards</button>
  </li>

  <!-- Rewards Admin -->
  <li *ngIf="isGuideOrAdmin" class="list-item d-none">
    <button #rewardsMenu [class.-active]="isRewardsListActive" class="list-item" kendoButton>Rewards</button>
    <kendo-contextmenu #rewardsPopup [alignToAnchor]="true" [target]="rewardsMenu" showOn="click">
      <ng-template kendoContextMenuTemplate>
        <div
          (click)="rewardsPopup.hide()"
          *ngFor="let item of rewardsList"
          [routerLink]="item.link"
          class="k-p-3 k-cursor-pointer"
          routerLinkActive="-active">
          {{ item.title }}
        </div>
      </ng-template>
    </kendo-contextmenu>
  </li>

  <li class="list-item">
    <button #leaderboardsMenu [class.-active]="isLeaderboardsListActive" class="nav-button" kendoButton>
      Leaderboards
    </button>
    <kendo-contextmenu #leaderboardsPopup [alignToAnchor]="true" [target]="leaderboardsMenu" showOn="click">
      <ng-template kendoContextMenuTemplate>
        <div
          (click)="leaderboardsPopup.hide()"
          *ngFor="let item of visibleLeaderboards$ | async"
          class="k-p-3 k-cursor-pointer"
          routerLink="/leaderboards/{{ item.key?.toLowerCase() }}"
          routerLinkActive="-active">
          {{ item.name }}
        </div>
      </ng-template>
    </kendo-contextmenu>
  </li>

  <li *ngIf="isAdmin" class="list-item">
    <button #analyticsMenu [class.-active]="isAnalyticsListActive" class="nav-button" kendoButton>Analytics</button>
    <kendo-contextmenu #analyticsPopup [alignToAnchor]="true" [target]="analyticsMenu" showOn="click">
      <ng-template kendoContextMenuTemplate>
        <div
          (click)="analyticsPopup.hide()"
          *ngFor="let item of analyticsList"
          [routerLink]="item.link"
          class="k-p-3 k-cursor-pointer"
          routerLinkActive="-active">
          {{ item.title }}
        </div>
      </ng-template>
    </kendo-contextmenu>
  </li>

  <li *ngIf="isGuideOrAdmin" class="list-item">
    <button #adminMenu [class.-active]="isAdminListActive" class="nav-button" kendoButton>Admin</button>
    <kendo-contextmenu #adminPopup [alignToAnchor]="true" [target]="adminMenu" showOn="click">
      <ng-template kendoContextMenuTemplate>
        <div
          (click)="adminPopup.hide()"
          *ngFor="let item of adminList"
          [routerLink]="item.link"
          class="k-p-3 k-cursor-pointer"
          routerLinkActive="-active">
          {{ item.title }}
        </div>
      </ng-template>
    </kendo-contextmenu>
  </li>

  <li *ngIf="isGuideOrAdmin" class="list-item">
    <button class="nav-button" kendoButton routerLink="/content-generator" routerLinkActive="-active">Beta</button>
  </li>
</ul>
