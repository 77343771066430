export const getQuizStatus = /* GraphQL */ `
  query GetQuizStatus($studentId: String, $externalStudentId: String, $appName: String!, $subjectName: String!, $quizType: String!) {
    getQuizStatus(studentId: $studentId, externalStudentId: $externalStudentId, appName: $appName, subjectName: $subjectName, quizType: $quizType) 
  }
`;
export const getCopilotLearningResources = /* GraphQL */ `
  query GetCopilotLearningResources($ixlThirdPartyLevelId: String!) {
    getCopilotLearningResources(ixlThirdPartyLevelId: $ixlThirdPartyLevelId) {
		name
		url
		learningAppName
		order
	}
  }
`;
export const getExplanationFromAI = /* GraphQL */ `
  query GetExplanationFromAI($email: AWSEmail!, $pageTitle: String!, $pageUrl: String!, $queryText: String!) {
    getExplanationFromAI(email: $email, pageTitle: $pageTitle, pageUrl: $pageUrl, queryText: $queryText) {
		id
		response
	}
  }
`;
export const getPageExplanationFromAI = /* GraphQL */ `
  query GetPageExplanationFromAI($email: AWSEmail!, $pageTitle: String!, $pageText: String!) {
    getPageExplanationFromAI(email: $email, pageTitle: $pageTitle, pageText: $pageText) {
		id
		response
	}
  }
`;
export const getCopilotScripts = /* GraphQL */ `
  query GetCopilotScripts {
    getCopilotScripts {
		name
		description
		app
		urlPatterns
		status
		code
	}
  }
`;
export const getCopilotNotificationConfigurations = /* GraphQL */ `
  query GetCopilotNotificationConfigurations {
    getCopilotNotificationConfigurations {
		name
		configuration
		status
	}
  }
`;
export const checkPersistAppIntegrationSubscription = /* GraphQL */ `
  query CheckPersistAppIntegrationSubscription($subscriptionId: String!) {
    checkPersistAppIntegrationSubscription(subscriptionId: $subscriptionId) {
		subscriptionId
		status
		message
	}
  }
`;
export const listStudentMapScores = /* GraphQL */ `
  query ListStudentMapScores {
    listStudentMapScores {
		id
		percentile
		rit_score
		student_id
		subject_id
		term_name
		term_season
		test_date
	}
  }
`;
export const listSubjects = /* GraphQL */ `
  query ListSubjects($onlyMapSubjects: Boolean) {
    listSubjects(onlyMapSubjects: $onlyMapSubjects) {
		id
		name
		image
	}
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions {
    listSessions {
		id
		end
		name
		schoolYear
		start
		isCurrent
		label
	}
  }
`;
export const listLearningApps = /* GraphQL */ `
  query ListLearningApps {
    listLearningApps {
		id
		createdOn
		lastModifiedOn
		learningAppName
		learningAppImage
		effortProfileRollupFormat
		effortProfileRollupActive
		adaptiveLevelsProgress
		hasMasteryProgression
		masteryThresholds {
		id
		createdOn
		lastModifiedOn
		learningAppId
		thresholdPercentage
		thresholdLabel
	}
	}
  }
`;
export const getLearningApps = /* GraphQL */ `
  query GetLearningApps {
    getLearningApps {
		learningAppId
		learningAppName
		learningAppImage
		subjectName
		subjectImage
		subjectOrder
		timeIntegration
		productivityIntegration
		courses {
		courseName
		activityUnit
		learningUnit
	}
	}
  }
`;
export const getLearningAppProgress = /* GraphQL */ `
  query GetLearningAppProgress($input: GetLearningAppProgressInput!) {
    getLearningAppProgress(input: $input) {
		platformId
		courseName
		courseId
		masteredLevels
		totalLevels
		masteryPercentage
		thresholdMasteryProgressPercentage
		studentGrade
		isActive
		deactivatedAt
		deactivationReason
		topics {
		platformId
		topicName
		topicId
		masteredLevels
		totalLevels
		masteryPercentage
		thresholdMasteryProgressPercentage
		studentGrade
		order
		isActive
		deactivatedAt
		deactivationReason
		levels {
		platformId
		levelName
		levelId
		activeMinutes
		activityUnitsAttempted
		activityUnitsCorrect
		masteryPercentage
		thresholdMasteryProgressPercentage
		studentGrade
		masteredAt
		order
		isActive
		deactivatedAt
		deactivationReason
	}
	}
	}
  }
`;
export const getLearningAppStatus = /* GraphQL */ `
  query GetLearningAppStatus {
    getLearningAppStatus {
		id
		learningAppName
		learningAppImage
		status
		comment
	}
  }
`;
export const getAlphaLearningLevels = /* GraphQL */ `
  query GetAlphaLearningLevels {
    getAlphaLearningLevels {
		level
	}
  }
`;
export const getSkillsCourses = /* GraphQL */ `
  query GetSkillsCourses($studentId: String!, $excludeNotCovered: Boolean, $startGrade: Int, $endGrade: Int) {
    getSkillsCourses(studentId: $studentId, excludeNotCovered: $excludeNotCovered, startGrade: $startGrade, endGrade: $endGrade) {
		name
		description
		grade
		order
		courseStatus
		masteryPercentage
		masteryExternalObjectives {
		name
	}
		standards {
		externalId
		description
		domainName
		domainAlias
		masteryStatus
		learningResources {
		name
		url
		type
		learningAppName
		order
	}
		isSelfAssessed
	}
		testUrl
	}
  }
`;
export const getIXLMathStackCourses = /* GraphQL */ `
  query GetIXLMathStackCourses($studentId: String!, $startGrade: Int, $endGrade: Int) {
    getIXLMathStackCourses(studentId: $studentId, startGrade: $startGrade, endGrade: $endGrade) {
		name
		description
		grade
		order
		courseStatus
		masteryPercentage
		standards {
		externalId
		description
		domainName
		domainAlias
		masteryStatus
		learningResources {
		name
		url
		type
		learningAppName
		order
		masteryPercentage
		isMastered
	}
		isSelfAssessed
	}
		testUrl
		masteryExternalObjectives {
		name
	}
	}
  }
`;
export const getStudentHouseAssignments = /* GraphQL */ `
  query GetStudentHouseAssignments {
    getStudentHouseAssignments {
		studentId
		houseId
		houseName
		houseImage
		guideId
		guideName
	}
  }
`;
export const getCourseEffortEnabledApps = /* GraphQL */ `
  query GetCourseEffortEnabledApps($studentId: String) {
    getCourseEffortEnabledApps(studentId: $studentId) {
		learningAppId
		learningAppName
		learningAppImage
		topicId
		topicName
		courseId
		courseName
		subjectId
	}
  }
`;
export const getMaintenanceStatus = /* GraphQL */ `
  query GetMaintenanceStatus($userId: String) {
    getMaintenanceStatus(userId: $userId) {
		isActive
		message
		startTime
		endTime
		hasMaintenanceAccess
	}
  }
`;
export const getActivityPasses = /* GraphQL */ `
  query GetActivityPasses($studentId: String!) {
    getActivityPasses(studentId: $studentId) {
		id
		name
		imageUrl
		expirationDate
	}
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($studentId: String!, $page: Int, $recordsPerPage: Int) {
    getNotifications(studentId: $studentId, page: $page, recordsPerPage: $recordsPerPage) {
		id
		studentId
		notificationType
		title
		message
		isRead
		createdOn
	}
  }
`;
export const getSATOnTrackReport = /* GraphQL */ `
  query GetSATOnTrackReport($scoreType: SATReportScoreType!) {
    getSATOnTrackReport(scoreType: $scoreType) {
		totalStudents
		totalStudentsOnTrack
		percentStudentsOnTrack
		data {
		name
		sessionOrderKey
		session
		score
		testDate
		gradeLevel
		termName
		testName
		lineType
	}
	}
  }
`;
export const getStandards = /* GraphQL */ `
  query GetStandards($subjectName: String) {
    getStandards(subjectName: $subjectName) {
		subjects {
		name
		grades {
		grade
		domains {
		externalId
		name
		organization
		clusters {
		externalId
		name
		standards {
		externalId
		description
	}
	}
	}
	}
	}
	}
  }
`;
export const checkVersionCompatibility = /* GraphQL */ `
  query CheckVersionCompatibility($frontEndProduct: String!, $frontEndVersion: String!) {
    checkVersionCompatibility(frontEndProduct: $frontEndProduct, frontEndVersion: $frontEndVersion) {
		status
		message
		androidUpgradeUrl
		iosUpgradeUrl
	}
  }
`;
export const getGrades = /* GraphQL */ `
  query GetGrades {
    getGrades {
		id
		grade
		name
		orderNumber
	}
  }
`;
export const getCurriculum = /* GraphQL */ `
  query GetCurriculum($filters: GetCurriculumInput) {
    getCurriculum(filters: $filters) {
		subjects {
		platformId
		name
		image
		courses {
		platformId
		name
		order
		grade {
		grade
		name
	}
		useInContentGeneration
		mainImageUrl
		thumbnailUrl
		domains {
		platformId
		externalId
		name
		alias
		order
		useInContentGeneration
		category
		imageUrl
		thumbnailUrl
		clusters {
		platformId
		externalId
		name
		order
		useInContentGeneration
		imageUrl
		thumbnailUrl
		standards {
		platformId
		externalId
		parentId
		description
		descriptionPlus
		order
		imageUrl
		thumbnailUrl
	}
	}
	}
	}
	}
	}
  }
`;
export const getStandardDependencies = /* GraphQL */ `
  query GetStandardDependencies($input: GetStandardDependenciesInput!) {
    getStandardDependencies(input: $input) {
		next
		data {
		platformId
		id
		parentPlatformId
		name
		description
		dependsOn {
		platformId
		id
		parentPlatformId
		name
		description
		type
	}
	}
	}
  }
`;
export const getAcademicCourseGradeThresholds = /* GraphQL */ `
  query GetAcademicCourseGradeThresholds($input: GetAcademicCourseGradeThresholdsInput!) {
    getAcademicCourseGradeThresholds(input: $input) {
		academicCourseName
		platformAcademicCourseId
		subject
		studentGradeThresholds {
		studentGrade
		masteryPercentageThreshold
	}
	}
  }
`;
export const getKhanAcademyMasteryCheck = /* GraphQL */ `
  query GetKhanAcademyMasteryCheck($input: GetKhanAcademyMasteryCheckInput!) {
    getKhanAcademyMasteryCheck(input: $input) {
		levelId
		masteredThroughExercise
		masteredThroughExerciseAt
	}
  }
`;
export const getDailyStudentLearningData = /* GraphQL */ `
  query GetDailyStudentLearningData($input: GetDailyStudentLearningDataInput!) {
    getDailyStudentLearningData(input: $input) {
		student
		email
		schoolStudentId
		school
		dailyLearningAppData {
		date
		subject
		learningAppName
		activeMinutes
		activityLastUpdatedAt
		masteryLastUpdatedAt
		activeMinutesLastUpdatedAt
		masteryTestResultLastUpdatedAt
		masteryTestResults {
		platformId
		name
		masteryTestId
		course
		subject
		platformResultId
		masteryTestResultId
		sentAt
		submittedAt
		grade
		questionsAnswered
		accuracy
		status
		url
		masteryTestUrl
		score
		questionResults {
		platformId
		platformQuestionResultId
		id
		result
		answer
		groupId
		levelId
		topicId
		masteryPercentage
		score
		maxScore
		state
		questionShownAt
		questionAnsweredAt
		questionExplanationShownAt
		questionFinishedReadingExplanationAt
		rubricItemResults {
		name
		maxScore
		score
		feedback
	}
	}
	}
		courses {
		courseId
		platformCourseId
		course
		activityUnitsCorrect
		activityUnitsAttempted
		levelsMastered
		topics {
		topicName
		topicId
		platformTopicId
		activityUnitsCorrect
		activityUnitsAttempted
		levelsMastered
		levels {
		levelName
		levelId
		platformLevelId
		masteryPercentage
		activityUnitsCorrect
		activityUnitsAttempted
	}
	}
	}
		antipatternFindings {
		antipatternName
		startedOn
		endedOn
		wastedMinutes
	}
	}
	}
  }
`;
export const getLeaderboards = /* GraphQL */ `
  query GetLeaderboards($alphaLearningLevel: String, $learningLevel: String, $type: String!) {
    getLeaderboards(alphaLearningLevel: $alphaLearningLevel, learningLevel: $learningLevel, type: $type) {
		id
		name
		shortName
		description
		orderNumber
		image
		addAllFilter
		filterLabels
		timeFrames {
		timeFrame
		labels
	}
		type
		timestampSource
	}
  }
`;
export const getLeaderboardsV2 = /* GraphQL */ `
  query GetLeaderboardsV2 {
    getLeaderboardsV2 {
		id
		key
		name
		description
		shortName
		image
		quicksightDashboard
		metricHeader
		additionalDataHeader
		dataLastUpdatedAt
	}
  }
`;
export const getLearningDashboardUrl = /* GraphQL */ `
  query GetLearningDashboardUrl($studentId: String!) {
    getLearningDashboardUrl(studentId: $studentId) {
		data {
		url
		parameters {
		studentId
	}
	}
	}
  }
`;
export const getUrlForLeaderboard = /* GraphQL */ `
  query GetUrlForLeaderboard($leaderboardId: String!, $studentId: String!) {
    getUrlForLeaderboard(leaderboardId: $leaderboardId, studentId: $studentId) {
		embedUrl
		parameters {
		studentId
		leaderboardKey
		metricHeader
		additionalDataHeader
		periodicity
		period
	}
	}
  }
`;
export const getStudentLeaderboard = /* GraphQL */ `
  query GetStudentLeaderboard($leaderboardId: String!, $timeFrame: String!, $timeFrameLabel: String, $filterLabel: String) {
    getStudentLeaderboard(leaderboardId: $leaderboardId, timeFrame: $timeFrame, timeFrameLabel: $timeFrameLabel, filterLabel: $filterLabel) {
		id
		studentId
		studentName
		studentImage
		alphaLearningLevel
		learningLevel
		timeFrame
		timeFrameLabel
		metricValue
		metricLabel
		filterLabel
		ranking
	}
  }
`;
export const getHouseLeaderboard = /* GraphQL */ `
  query GetHouseLeaderboard($leaderboardId: String!, $timeFrame: String!, $timeFrameLabel: String, $filterLabel: String) {
    getHouseLeaderboard(leaderboardId: $leaderboardId, timeFrame: $timeFrame, timeFrameLabel: $timeFrameLabel, filterLabel: $filterLabel) {
		houseName
		houseImage
		guideName
		guideImage
		alphaLearningLevel
		learningLevel
		timeFrame
		timeFrameLabel
		metricValue
		metricLabel
		filterLabel
		ranking
		students {
		studentId
		studentName
		studentImage
		metricValue
		ranking
	}
	}
  }
`;
export const getMinutesPerDayChartDataPoints = /* GraphQL */ `
  query GetMinutesPerDayChartDataPoints($studentId: String!, $subjectId: String!) {
    getMinutesPerDayChartDataPoints(studentId: $studentId, subjectId: $subjectId) {
		date
		minutesAtHome
		minutesAtSchool
		learningAppName
		mostTimeWorkedCourseName
		mostTimeWorkedTopicName
	}
  }
`;
export const getProductivityChartDataPoints = /* GraphQL */ `
  query GetProductivityChartDataPoints($studentId: String!, $subjectId: String!) {
    getProductivityChartDataPoints(studentId: $studentId, subjectId: $subjectId) {
		date
		totalMinutesSpent
		totalRelativeProductivity
		learningApps {
		learningAppName
		levelsCompleted
		minutesSpent
		averageProductivity
		alphaAverageProductivity
		relativeProductivity
		learningUnit
		learningUnitPassed
		learningUnitPassedDescription
	}
	}
  }
`;
export const getAppActivityChartDataPoints = /* GraphQL */ `
  query GetAppActivityChartDataPoints($studentId: String!, $subjectId: String!) {
    getAppActivityChartDataPoints(studentId: $studentId, subjectId: $subjectId) {
		date
		learningAppName
		activityUnitLabel
		activityUnitsCorrect
		activityUnitsCorrectLabel
		activityUnitsAttempted
		activityProductivityMean
		topicLevelsPassed
		mostActivityCourseName
		mostActivityTopicName
		mostProgressCourseName
		mostProgressTopicName
		learningUnit
		learningUnitPassed
	}
  }
`;
export const getMapPercentileChartDataPoints = /* GraphQL */ `
  query GetMapPercentileChartDataPoints($studentId: String!, $subjectId: String!) {
    getMapPercentileChartDataPoints(studentId: $studentId, subjectId: $subjectId) {
		date
		percentile
		ritScore
		termName
		termSeason
	}
  }
`;
export const getStudentDashboardDetails = /* GraphQL */ `
  query GetStudentDashboardDetails($studentId: String!, $periodStartDate: String!, $periodEndDate: String!) {
    getStudentDashboardDetails(studentId: $studentId, periodStartDate: $periodStartDate, periodEndDate: $periodEndDate) {
		firstName
		lastName
		alphaLearningLevel
		mapGradeLevel
		alphaStudentId
		isActive
		subjects {
		savedMAPPercentile
		goalMAPPercentile
		subjectName
		subjectId
		lastYearMAPPercentile
		minutesAtHome
		minutesAtSchool
		goalMinutesPerDay
		goalProductiveTime
		estimatedGoalDate
		isMAPSubject
		appLastUpdatedOn
		timeTrackLastUpdateOn
		minutesPerDayDataPoints {
		date
		minutesAtHome
		minutesAtSchool
		learningAppName
		mostTimeWorkedCourseName
		mostTimeWorkedTopicName
	}
		productivityDataPoints {
		date
		totalMinutesSpent
		totalRelativeProductivity
		learningApps {
		learningAppName
		levelsCompleted
		minutesSpent
		averageProductivity
		alphaAverageProductivity
		relativeProductivity
		learningUnit
		learningUnitPassed
		learningUnitPassedDescription
	}
	}
		appActivityDataPoints {
		date
		learningAppName
		activityUnitLabel
		activityUnitsCorrect
		activityUnitsCorrectLabel
		activityUnitsAttempted
		activityProductivityMean
		topicLevelsPassed
		mostActivityCourseName
		mostActivityTopicName
		mostProgressCourseName
		mostProgressTopicName
		learningUnit
		learningUnitPassed
	}
	}
	}
  }
`;
export const getAggregateExpectedGrowthData = /* GraphQL */ `
  query GetAggregateExpectedGrowthData($alphaLearningLevel: String, $yearsAtAlpha: String, $learningLevel: String, $yearsWithCoachbot: String, $maxMapPercentile: Int, $subjectId: String) {
    getAggregateExpectedGrowthData(alphaLearningLevel: $alphaLearningLevel, yearsAtAlpha: $yearsAtAlpha, learningLevel: $learningLevel, yearsWithCoachbot: $yearsWithCoachbot, maxMapPercentile: $maxMapPercentile, subjectId: $subjectId) {
		subjectName
		subjectId
		baseTestDate
		testYear
		termSeason
		averageLearningRatio
		averageRitGrowth
		averageExpectedRitGrowth
		above2xPercent
		above2xTotal
		totalStudents
	}
  }
`;
export const getStudentExpectedGrowthData = /* GraphQL */ `
  query GetStudentExpectedGrowthData($alphaLearningLevel: String, $yearsAtAlpha: String, $learningLevel: String, $yearsWithCoachbot: String, $maxMapPercentile: Int, $subjectId: String, $studentId: String) {
    getStudentExpectedGrowthData(alphaLearningLevel: $alphaLearningLevel, yearsAtAlpha: $yearsAtAlpha, learningLevel: $learningLevel, yearsWithCoachbot: $yearsWithCoachbot, maxMapPercentile: $maxMapPercentile, subjectId: $subjectId, studentId: $studentId) {
		firstName
		lastName
		studentId
		subjectName
		subjectId
		baseTestDate
		testYear
		formerTestYear
		termSeason
		learningRatio
		ritScore
		expectedRitScore
		formerRitScore
		percentile
		expectedPercentile
		formerPercentile
		ritGrowth
		expectedRitGrowth
		hasDifferentNormDataSet
		key
	}
  }
`;
export const getLearningRateQuadrantReportSeasons = /* GraphQL */ `
  query GetLearningRateQuadrantReportSeasons {
    getLearningRateQuadrantReportSeasons {
		seasonName
		seasonYear
	}
  }
`;
export const getLearningRateQuadrantReportData = /* GraphQL */ `
  query GetLearningRateQuadrantReportData($alphaLearningLevel: String, $yearsAtAlpha: String, $learningLevel: String, $yearsWithCoachbot: String, $seasonName: String, $seasonYear: String) {
    getLearningRateQuadrantReportData(alphaLearningLevel: $alphaLearningLevel, yearsAtAlpha: $yearsAtAlpha, learningLevel: $learningLevel, yearsWithCoachbot: $yearsWithCoachbot, seasonName: $seasonName, seasonYear: $seasonYear) {
		firstName
		lastName
		level
		grade
		studentId
		subjectName
		subjectId
		formerRitScore
		formerPercentile
		ritScore
		percentile
		oneYearAverageRitScore
		oneYearAveragePercentile
		learningRate
		observedRitGrowth
		expectedRitGrowth
	}
  }
`;
export const getLearningLossReportData = /* GraphQL */ `
  query GetLearningLossReportData($studentId: ID!) {
    getLearningLossReportData(studentId: $studentId) {
		STUDENT_FIRST_NAME
		STUDENT_FULL_NAME
		CURRENT_GRADE
		CURRENT_GRADE_NUMBER
		CURRENT_GRADE_MINUS_1
		CURRENT_GRADE_MINUS_2
		DATE_CREATED
		MAP_POINTS_INCREASE_PER_YEAR
		MAP_OVERALL_SCORE
		MAP_SCORE_ROUNDED_UP
		MAP_SCORE_ROUNDED_UP_LOWEST_GRADE
		MAP_SCORE_ROUNDED_UP_LOWEST_GRADE_NUMBER
		MAP_SCORE_ROUNDED_UP_LOWEST_GRADE_PERCENTILE
		MAP_OVERALL_PERCENTILE
		MAP_GOALS {
		NAME
		SCORE
		PERCENTILE
	}
		MAP_OVERALL_PERCENTILE_POSITION
		FORECAST_SAT_SCORE_TRAJECTORY_VALUE
		FORECAST_SAT_SCORE_TRAJECTORY_POSITION
		MAP_POINTS_50_80
		MAP_POINTS_80_95
		MAP_POINTS_95_99
		KNOWLEDGE_GAPS_GRADES {
		GRADE
		NOT_PROFICIENT_STANDARDS
		TOTAL_STANDARDS
		PROFICIENT_STANDARDS
		PROFICIENT_PERCENTAGE
		DOMAINS {
		DOMAIN_NAME
		NOT_PROFICIENT_STANDARDS_FOR_DOMAIN
		PROFICIENT_STANDARDS_FOR_DOMAIN
		TOTAL_STANDARDS_FOR_DOMAIN
		DOMAIN_CLUSTERS {
		CLUSTER_NAME
		NOT_PROFICIENT_STANDARDS_FOR_CLUSTER
		PROFICIENT_STANDARDS_FOR_CLUSTER
		PROFICIENT_PERCENTAGE_FOR_CLUSTER
		TOTAL_STANDARDS_FOR_CLUSTER
	}
	}
		PLAN_LESSONS {
		STANDARD
		DOMAIN
		CLUSTER
		DESCRIPTION
		LESSONS {
		NAME
		URL
	}
	}
	}
		EFFORT_PERCENTILE_GAPS {
		EFFORT_TARGET_PERCENTILE
		EFFORT_TARGET_SCORE
		MAP_GOALS {
		NAME
		SCORE
		GAP_TO_TARGET_PERCENTILE
		HRS_FIX_SCHOOL
		HRS_FIX_APP_ALONE
		HRS_FIX_GT
	}
		MAP_GOALS_TOTAL_HRS_FIX_SCHOOL
		MAP_GOALS_TOTAL_HRS_FIX_APP_ALONE
		MAP_GOALS_TOTAL_HRS_FIX_GT
	}
	}
  }
`;
export const getQuickSightDashboardUrl = /* GraphQL */ `
  query GetQuickSightDashboardUrl($dashboardId: String!, $sessionLifetimeInMinutes: Int) {
    getQuickSightDashboardUrl(dashboardId: $dashboardId, sessionLifetimeInMinutes: $sessionLifetimeInMinutes) {
		data {
		url
		parameters {
		studentId
	}
	}
	}
  }
`;
export const getDataUpdatedOn = /* GraphQL */ `
  query GetDataUpdatedOn($studentId: String) {
    getDataUpdatedOn(studentId: $studentId) {
		topicMastery
		learningSessions
	}
  }
`;
export const getTimeToMAPScore = /* GraphQL */ `
  query GetTimeToMAPScore($studentId: String!, $subjectId: String!, $savedMAPPercentile: Int!, $goalMAPPercentile: Int!) {
    getTimeToMAPScore(studentId: $studentId, subjectId: $subjectId, savedMAPPercentile: $savedMAPPercentile, goalMAPPercentile: $goalMAPPercentile) {
		gradeLevel
		timeInMinutes
		subjectId
	}
  }
`;
export const getMAPEffortData = /* GraphQL */ `
  query GetMAPEffortData($minRITScore: String, $maxRITScore: String) {
    getMAPEffortData(minRITScore: $minRITScore, maxRITScore: $maxRITScore) {
		subjectId
		subjectName
		subjectImage
		minRITScore
		maxRITScore
		mapEffortData {
		min
		max
		label
		frequency
		startRITScore
		durationMinutes
		standardDeviation
	}
	}
  }
`;
export const getAverageAppCompletionLevels = /* GraphQL */ `
  query GetAverageAppCompletionLevels($learningAppId: String!, $courseId: String, $topicId: String) {
    getAverageAppCompletionLevels(learningAppId: $learningAppId, courseId: $courseId, topicId: $topicId) {
		levelNumber
		averageActivityUnitsAttempted
		averageActivityUnitsCorrect
		averageDurationMinutes
		frequency
	}
  }
`;
export const listInteractiveMAPGrowthReport = /* GraphQL */ `
  query ListInteractiveMAPGrowthReport {
    listInteractiveMAPGrowthReport {
		percentile
		studentId
		subjectId
		alphaLearningLevel
		yearsAtAlpha
	}
  }
`;
export const getLearningAppMessages = /* GraphQL */ `
  query GetLearningAppMessages($input: GetLearningAppMessagesInput!) {
    getLearningAppMessages(input: $input) {
		id
		platformStudentId
		learningAppName
		messageType
		payload
		viewed
		createdOn
		viewedOn
	}
  }
`;
export const getLearningSessions = /* GraphQL */ `
  query GetLearningSessions($input: GetLearningSessionsInput!) {
    getLearningSessions(input: $input) {
		id
		startTime
		endTime
		duration
		url
		platformCourseId
		courseId
		courseName
		subject
		platformTopicId
		topicId
		topicName
		platformLevelId
		levelId
		levelName
	}
  }
`;
export const getConfiguration = /* GraphQL */ `
  query GetConfiguration($input: GetConfigurationInput!) {
    getConfiguration(input: $input) {
		configuration {
		name
		key
		value
	}
	}
  }
`;
export const getConfigurations = /* GraphQL */ `
  query GetConfigurations($input: GetConfigurationsInput!) {
    getConfigurations(input: $input) {
		configurations {
		name
		key
		value
	}
	}
  }
`;
export const getDMChatHistory = /* GraphQL */ `
  query GetDMChatHistory($input: GetDMChatHistoryInput!) {
    getDMChatHistory(input: $input) {
		chatHistory {
		message
		senderUserId
		timestamp
		platformGeneratedContentId
	}
		hasNextPage
	}
  }
`;
export const getHistoricalFigures = /* GraphQL */ `
  query GetHistoricalFigures($input: GetHistoricalFiguresInput!) {
    getHistoricalFigures(input: $input) {
		platformId
		name
		imageUrl
		thumbnailUrl
		subjectsITeach {
		id
		name
		image
	}
		properties
	}
  }
`;
export const getGeneratedContentComments = /* GraphQL */ `
  query GetGeneratedContentComments($input: GetGeneratedContentCommentsInput!) {
    getGeneratedContentComments(input: $input) {
		generatedContentId
		comments {
		commentId
		userId
		userPreferredName
		userProfileImage
		comment
		creationDateTime
		likesCount
		dislikesCount
		repliesCount
	}
	}
  }
`;
export const gradeContent = /* GraphQL */ `
  query GradeContent($input: GradeContentInput!) {
    gradeContent(input: $input) {
		result
		feedback
		score
		maxScore
		grade
		subParts {
		id
		name
		feedback
		result
		score
		maxScore
	}
	}
  }
`;
export const selectContent = /* GraphQL */ `
  query SelectContent($input: SelectContentInput!) {
    selectContent(input: $input) {
		content
		contentOutputFormat
		generatedContentId
		parentGeneratedContentId
		contentTypeId
		contentTypeName
		contentTypeVersion
		grade
		subjectName
		platformSubjectId
		courseName
		platformCourseId
		domainId
		domainDescription
		platformDomainId
		clusterId
		clusterDescription
		platformClusterId
		standardId
		standardName
		standardDescription
		platformStandardId
		difficulty
		attributes {
		attributeName
		attributeValue
	}
		extendedAttributes {
		platformId
		value
		type
	}
		totalLikes
		totalViews
		totalComments
		totalBookmarks
		totalShares
		totalReports
		isLikedByUser
		isSharedByUser
		isBookmarkedByUser
		isCommentedByUser
		selectionReason
		childGeneratedContents {
		content
		generatedContentId
		parentGeneratedContentId
		contentTypeId
		contentTypeName
		contentTypeVersion
		platformStandardId
		standardId
		attributes {
		attributeName
		attributeValue
	}
		extendedAttributes {
		platformId
		value
		type
	}
	}
		warnings {
		code
		message
	}
	}
  }
`;
export const getGeneratedContent = /* GraphQL */ `
  query GetGeneratedContent($id: String!) {
    getGeneratedContent(id: $id) {
		parentGeneratedContentId
		content
		generatedContentId
		contentTypeId
		contentTypeName
		contentTypeVersion
		grade
		subjectName
		platformSubjectId
		courseName
		platformCourseId
		domainId
		domainDescription
		platformDomainId
		clusterId
		clusterDescription
		platformClusterId
		standardId
		standardName
		standardDescription
		platformStandardId
		difficulty
		attributes {
		attributeName
		attributeValue
	}
		extendedAttributes {
		platformId
		value
		type
	}
		totalLikes
		totalViews
		totalComments
		totalBookmarks
		totalShares
		totalReports
		isLikedByUser
		isSharedByUser
		isBookmarkedByUser
		isCommentedByUser
		selectionReason
		warnings {
		code
		message
	}
	}
  }
`;
export const getGeneratedContentItems = /* GraphQL */ `
  query GetGeneratedContentItems($input: GetGeneratedContentItemsInput!) {
    getGeneratedContentItems(input: $input) {
		items {
		parentGeneratedContentId
		content
		generatedContentId
		contentTypeId
		contentTypeName
		contentTypeVersion
		grade
		subjectName
		platformSubjectId
		courseName
		platformCourseId
		domainId
		domainDescription
		platformDomainId
		clusterId
		clusterDescription
		platformClusterId
		standardId
		standardName
		standardDescription
		platformStandardId
		difficulty
		attributes {
		attributeName
		attributeValue
	}
		extendedAttributes {
		platformId
		value
		type
	}
		totalLikes
		totalViews
		totalComments
		totalBookmarks
		totalShares
		totalReports
		isLikedByUser
		isSharedByUser
		isBookmarkedByUser
		isCommentedByUser
		selectionReason
		warnings {
		code
		message
	}
	}
	}
  }
`;
export const getInteractedContent = /* GraphQL */ `
  query GetInteractedContent($input: InteractedContentInput!) {
    getInteractedContent(input: $input) {
		items {
		parentGeneratedContentId
		content
		generatedContentId
		contentTypeId
		contentTypeName
		contentTypeVersion
		grade
		subjectName
		platformSubjectId
		courseName
		platformCourseId
		domainId
		domainDescription
		platformDomainId
		clusterId
		clusterDescription
		platformClusterId
		standardId
		standardName
		standardDescription
		platformStandardId
		difficulty
		attributes {
		attributeName
		attributeValue
	}
		extendedAttributes {
		platformId
		value
		type
	}
		totalLikes
		totalViews
		totalComments
		totalBookmarks
		totalShares
		totalReports
		isLikedByUser
		isSharedByUser
		isBookmarkedByUser
		isCommentedByUser
		selectionReason
		warnings {
		code
		message
	}
	}
		totalCount
	}
  }
`;
export const getContentItemCounts = /* GraphQL */ `
  query GetContentItemCounts($input: GetContentItemCountsInput!) {
    getContentItemCounts(input: $input) {
		subject {
		name
		id
	}
		course {
		name
		id
	}
		domain {
		externalId
		id
	}
		standards {
		id
		externalId
		contentCounts {
		contentType
		version
		count
	}
	}
	}
  }
`;
export const getTutoringSessionConfig = /* GraphQL */ `
  query GetTutoringSessionConfig($input: GetTutoringSessionConfigInput!) {
    getTutoringSessionConfig(input: $input) {
		tutorConfig {
		initialConfig {
		contentGeneratorId
		contentTypeId
	}
		continuingConfig {
		contentGeneratorId
		contentTypeId
	}
	}
		keyFigure {
		platformId
		name
		imageUrl
		thumbnailUrl
		properties {
		alphaAvatarId
	}
	}
		isContinuingSession
		subjectName
		domainId
		clusterId
		standardId
	}
  }
`;
export const getContentGenExtendedAttributeTypes = /* GraphQL */ `
  query GetContentGenExtendedAttributeTypes($input: GetContentGenExtendedAttributeTypesInput!) {
    getContentGenExtendedAttributeTypes(input: $input) {
		next
		data {
		platformId
		type
	}
	}
  }
`;
export const getContentGenExtendedAttributeMappings = /* GraphQL */ `
  query GetContentGenExtendedAttributeMappings($input: GetContentGenExtendedAttributeMappingsInput!) {
    getContentGenExtendedAttributeMappings(input: $input) {
		next
		data {
		platformId
		id
		type
		value
		properties
		category
		relatesTo {
		platformId
		id
		type
		value
		properties
		category
		relation
	}
	}
	}
  }
`;
export const getContentGenStandardExtendedAttributeMappings = /* GraphQL */ `
  query GetContentGenStandardExtendedAttributeMappings($input: GetContentGenStandardExtendedAttributeMappingsInput!) {
    getContentGenStandardExtendedAttributeMappings(input: $input) {
		next
		data {
		platformId
		id
		parentPlatformId
		name
		description
		extendedAttributes {
		platformId
		id
		type
		value
		properties
		category
	}
	}
	}
  }
`;
export const selectContentForTest = /* GraphQL */ `
  query SelectContentForTest($input: SelectContentForTestInput!) {
    selectContentForTest(input: $input) {
		items {
		content
		contentOutputFormat
		generatedContentId
		parentGeneratedContentId
		contentTypeId
		contentTypeName
		contentTypeVersion
		grade
		subjectName
		platformSubjectId
		courseName
		platformCourseId
		domainId
		domainDescription
		platformDomainId
		clusterId
		clusterDescription
		platformClusterId
		standardId
		standardName
		standardDescription
		platformStandardId
		difficulty
		attributes {
		attributeName
		attributeValue
	}
		extendedAttributes {
		platformId
		value
		type
	}
		totalLikes
		totalViews
		totalComments
		totalBookmarks
		totalShares
		totalReports
		isLikedByUser
		isSharedByUser
		isBookmarkedByUser
		isCommentedByUser
		selectionReason
		childGeneratedContents {
		content
		generatedContentId
		parentGeneratedContentId
		contentTypeId
		contentTypeName
		contentTypeVersion
		platformStandardId
		standardId
		attributes {
		attributeName
		attributeValue
	}
		extendedAttributes {
		platformId
		value
		type
	}
	}
		warnings {
		code
		message
	}
	}
	}
  }
`;
export const getContentGenConfigs = /* GraphQL */ `
  query GetContentGenConfigs($input: GetContentGenConfigsInput!) {
    getContentGenConfigs(input: $input) {
		platformId
		id
		version
		promptTemplate
		promptOutputSchema
		contentGeneratorType
		status
		generationType
	}
  }
`;
export const getDailyGoalTracking = /* GraphQL */ `
  query GetDailyGoalTracking($studentId: ID!, $startDate: String!, $endDate: String!) {
    getDailyGoalTracking(studentId: $studentId, startDate: $startDate, endDate: $endDate) {
		dailyGoalTrackingId
		subjectId
		date
		measurement
		time
		activeMinutes
		timeGoal
		timeGoalLimit
		timeAchieved
		activity
		activityGoal
		activityGoalLimit
		activityAchieved
		accuracy
		accuracyGoal
		accuracyGoalLimit
		accuracyAchieved
		learningStatus
		timeGoalAchievedPercent
		dailyGoalAchieved
		feedback
		feedbackReason
		appName
		courseName
		wastedMinutes
		antipatternFindings {
		antipatternFindingId
		antipatternName
		timeWastedPercentage
		specificDescription
		learningApp
		startedOn
		endedOn
		evidence
		feedback
		feedbackNotes
		status
		wastedMinutes
	}
	}
  }
`;
export const getPracticeSchedules = /* GraphQL */ `
  query GetPracticeSchedules($studentId: ID!) {
    getPracticeSchedules(studentId: $studentId) {
		itemsCount
		items {
		id
		studentId
		subjectId
		createdByUserId
		createdOn
		lastModifiedOn
		startDate
		status
		replacementDate
		measurement
		monday
		tuesday
		wednesday
		thursday
		friday
		saturday
		sunday
	}
	}
  }
`;
export const getDailyGoalTrackingFeedbackReasons = /* GraphQL */ `
  query GetDailyGoalTrackingFeedbackReasons($isActive: Boolean) {
    getDailyGoalTrackingFeedbackReasons(isActive: $isActive) {
		id
		name
		displayText
		requiresDescription
		awardsCompletion
		sortOrder
		isActive
	}
  }
`;
export const getDailyPracticeHabits = /* GraphQL */ `
  query GetDailyPracticeHabits($trailingNumberOfDays: Int!, $studentId: String!) {
    getDailyPracticeHabits(trailingNumberOfDays: $trailingNumberOfDays, studentId: $studentId) {
		consistentPractice {
		level
		currentScore
		personalBest
		message
	}
		lotsOfPractice {
		level
		currentScore
		personalBest
		message
	}
		deliberatePractice {
		level
		currentScore
		personalBest
		message
	}
		overallHabitLevel {
		level
		levelImageUrl
		levelTitle
	}
	}
  }
`;
export const getDailyPracticeHabitsLearningTimeBlocks = /* GraphQL */ `
  query GetDailyPracticeHabitsLearningTimeBlocks($weekStartDate: String, $studentId: String!) {
    getDailyPracticeHabitsLearningTimeBlocks(weekStartDate: $weekStartDate, studentId: $studentId) {
		learningTimeBlocks {
		startTime
		endTime
		duration
		subjectId
		learningApp
		learningAppDescription
		dailyActiveMinutes
		dailyTime
		dailyTimeGoal
		dailyTimeGoalLimit
		dailyTimeAchieved
		dailyActivity
		dailyActivityGoal
		dailyActivityGoalLimit
		dailyActivityAchieved
		dailyAccuracy
		dailyAccuracyGoal
		dailyAccuracyGoalLimit
		dailyAccuracyAchieved
		dailyLearningStatus
	}
	}
  }
`;
export const getLearningAppCurriculum = /* GraphQL */ `
  query GetLearningAppCurriculum($input: LearningAppCurriculumInput!) {
    getLearningAppCurriculum(input: $input) {
		platformId
		courseName
		courseId
		subject
		activityUnit
		activityUnitCorrect
		learningUnit
		learningUnitCorrect
		isActive
		deactivatedAt
		deactivationReason
		academicCourseName
		grade
		topics {
		platformId
		topicName
		topicId
		order
		url
		isActive
		deactivatedAt
		deactivationReason
		levels {
		platformId
		levelName
		levelId
		order
		url
		isActive
		deactivatedAt
		deactivationReason
	}
	}
	}
  }
`;
export const getLearningAppCourseEnrollments = /* GraphQL */ `
  query GetLearningAppCourseEnrollments($input: GetLearningAppCourseEnrollmentsInput!) {
    getLearningAppCourseEnrollments(input: $input) {
		subjectName
		courseName
		enrolledAt
		unenrolledAt
		platformCourseId
		courseId
	}
  }
`;
export const getLearningAppCourseEnrollmentsCount = /* GraphQL */ `
  query GetLearningAppCourseEnrollmentsCount($input: GetLearningAppCourseEnrollmentsCountInput!) {
    getLearningAppCourseEnrollmentsCount(input: $input) {
		courseId
		courseName
		enrollmentCount
	}
  }
`;
export const getLearningAppCourseStats = /* GraphQL */ `
  query GetLearningAppCourseStats($input: GetLearningAppCourseStatsInput!) {
    getLearningAppCourseStats(input: $input) {
		subjectName
		courseName
		platformCourseId
		courseId
		activityUnitsCorrect
		activityUnitsTotal
	}
  }
`;
export const getMaxGrade = /* GraphQL */ `
  query GetMaxGrade($input: GetMaxGradeInput!) {
    getMaxGrade(input: $input) {
		courseName
		topics {
		topicName
		topicId
		maxMasteryPercentage
		maxGrade
		maxGradeAchievedAt
	}
	}
  }
`;
export const getStudentXP = /* GraphQL */ `
  query GetStudentXP($input: GetStudentXPInput!) {
    getStudentXP(input: $input) {
		maxXP
		totalXP
		grades {
		name
		grade
		gradeXP
		maxGradeXP
		academicCourses {
		name
		platformAcademicCourseId
		order
		maxAcademicCourseXP
		academicCourseXP
		domains {
		name
		domainId
		platformDomainId
		order
		maxDomainXP
		domainXP
		clusters {
		name
		clusterId
		platformClusterId
		order
		maxClusterXP
		clusterXP
		standards {
		standardId
		platformStandardId
		maxStandardXP
		standardXP
	}
	}
	}
	}
	}
	}
  }
`;
export const getStudentXPv2 = /* GraphQL */ `
  query GetStudentXPv2($input: GetStudentXPv2Input!) {
    getStudentXPv2(input: $input) {
		courses {
		courseEarnedXP
		courseDailyEarnedXP
		courseWeeklyEarnedXP
		domains {
		name
		domainId
		platformDomainId
		clusters {
		name
		clusterId
		platformClusterId
		clusterEarnedXP
		clusterTotalXP
		standards {
		standardId
		platformStandardId
	}
	}
	}
	}
	}
  }
`;
export const getLastInteractedStandard = /* GraphQL */ `
  query GetLastInteractedStandard($input: GetLastInteractedStandardInput!) {
    getLastInteractedStandard(input: $input) {
		lastInteractedStandardForSubjects {
		subjectName
		grade {
		name
		platformGradeId
		academicCourse {
		name
		platformCourseId
		domain {
		platformDomainId
		organizationName
		name
		domainId
		cluster {
		platformClusterId
		organizationName
		name
		clusterId
		standard {
		standardId
		platformStandardId
		organizationName
	}
	}
	}
	}
	}
		lastInteractedAt
	}
	}
  }
`;
export const getMasteryTest = /* GraphQL */ `
  query GetMasteryTest($input: GetMasteryTestInput!) {
    getMasteryTest(input: $input) {
		platformId
		name
		course
		subject
		totalQuestions
		url
		status
		appSpecificType
		type
		masteryTestGroup {
		id
		name
	}
		questions {
		platformId
		id
		sequenceNumber
		name
		difficulty
		url
		standards {
		standardId
		status
	}
		appLevel {
		id
		platformId
		name
	}
		groupId
	}
		maxScore
	}
  }
`;
export const getMasteryTestQuestion = /* GraphQL */ `
  query GetMasteryTestQuestion($input: GetMasteryTestQuestionInput!) {
    getMasteryTestQuestion(input: $input) {
		platformId
		name
		difficulty
		url
		answer
		masteryTests {
		platformId
		id
		sequenceNumber
	}
		standards {
		standardId
		status
	}
		appLevel {
		id
		platformId
		name
	}
		rubricItems {
		name
		description
		maxScore
		standard {
		platformStandardId
		standardId
	}
	}
		groupId
		maxScore
	}
  }
`;
export const getMasteryTestResult = /* GraphQL */ `
  query GetMasteryTestResult($input: GetMasteryTestResultInput!) {
    getMasteryTestResult(input: $input) {
		platformId
		id
		name
		learningAppName
		masteryTestId
		course
		subject
		platformResultId
		masteryTestResultId
		sentAt
		submittedAt
		questionsAnswered
		accuracy
		status
		url
		masteryTestUrl
		questionResults {
		platformId
		platformQuestionResultId
		id
		result
		answer
		groupId
		levelId
		topicId
		masteryPercentage
		score
		maxScore
		state
		questionShownAt
		questionAnsweredAt
		questionExplanationShownAt
		questionFinishedReadingExplanationAt
		rubricItemResults {
		name
		maxScore
		score
		feedback
	}
	}
		score
		maxScore
		grade
		gradePercentage
		studentGrade
		totalQuestions
		masteryTestType
		appSpecificMasteryTestType
		masteryTestGroup {
		id
		name
	}
	}
  }
`;
export const getMasteryTestResults = /* GraphQL */ `
  query GetMasteryTestResults($input: GetMasteryTestResultsInput!) {
    getMasteryTestResults(input: $input) {
		masteryTestResults {
		platformId
		id
		name
		learningAppName
		masteryTestId
		course
		subject
		platformResultId
		masteryTestResultId
		sentAt
		submittedAt
		questionsAnswered
		accuracy
		status
		url
		masteryTestUrl
		questionResults {
		platformId
		platformQuestionResultId
		id
		result
		answer
		groupId
		levelId
		topicId
		masteryPercentage
		score
		maxScore
		state
		questionShownAt
		questionAnsweredAt
		questionExplanationShownAt
		questionFinishedReadingExplanationAt
		rubricItemResults {
		name
		maxScore
		score
		feedback
	}
	}
		score
		maxScore
		grade
		gradePercentage
		studentGrade
		totalQuestions
		masteryTestType
		appSpecificMasteryTestType
		masteryTestGroup {
		id
		name
	}
	}
	}
  }
`;
export const getTestScorePercentile = /* GraphQL */ `
  query GetTestScorePercentile($input: GetTestScorePercentileInput!) {
    getTestScorePercentile(input: $input) {
		percentile
		percentileExtended
	}
  }
`;
export const getMasteryTestStats = /* GraphQL */ `
  query GetMasteryTestStats($input: GetMasteryTestStatsInput!) {
    getMasteryTestStats(input: $input) {
		academicCourses {
		academicCourseName
		subject
		totalNumberOfQuestionsAnswered
		totalNumberOfQuestionsCorrect
	}
	}
  }
`;
export const getStaticMasteryTest = /* GraphQL */ `
  query GetStaticMasteryTest($input: GetStaticMasteryTestInput!) {
    getStaticMasteryTest(input: $input) {
		platformId
		id
		name
		course
		subject
		totalQuestions
		url
		status
		appSpecificType
		type
		masteryTestGroup {
		id
		name
	}
		questions {
		platformId
		id
		sequenceNumber
		name
		difficulty
		url
		standards {
		standardId
		status
	}
		appLevel {
		id
		platformId
		name
	}
		groupId
	}
		maxScore
	}
  }
`;
export const getVoucherEnrollmentStatus = /* GraphQL */ `
  query GetVoucherEnrollmentStatus($input: GetVoucherEnrollmentStatusInput!) {
    getVoucherEnrollmentStatus(input: $input) {
		parentEmail
		studentEmail
		studentExists
		isEnrolled
		program
		startDate
		endDate
		voucher
		voucherType
		metadata
	}
  }
`;
export const getPaymentDetails = /* GraphQL */ `
  query GetPaymentDetails($input: GetPaymentDetailsInput!) {
    getPaymentDetails(input: $input) {
		payments {
		paymentProvider
		transactionId
		parentEmail
		studentEmail
		studentId
		voucherId
		startCourseId
		endCourseId
		subjectName
		enrollmentStartDate
		purchaseSource
		metadata
		voucherRedemptionDate
		program
		programEnrollmentStartDate
		programEnrollmentEndDate
	}
	}
  }
`;
export const getStandardsForQuiz = /* GraphQL */ `
  query GetStandardsForQuiz($input: GetStandardsForQuizInput!) {
    getStandardsForQuiz(input: $input) {
		standards {
		standardId
		platformStandardId
		difficulty
	}
	}
  }
`;
export const getRecommendedSkillsCourses = /* GraphQL */ `
  query GetRecommendedSkillsCourses($studentId: String!, $excludeNotCovered: Boolean, $startGrade: Int, $endGrade: Int) {
    getRecommendedSkillsCourses(studentId: $studentId, excludeNotCovered: $excludeNotCovered, startGrade: $startGrade, endGrade: $endGrade) {
		name
		description
		grade
		order
		courseStatus
		masteryPercentage
		masteryExternalObjectives {
		name
	}
		standards {
		externalId
		description
		domainName
		domainAlias
		masteryStatus
		learningResources {
		name
		url
		type
		learningAppName
		order
	}
		isSelfAssessed
	}
		testUrl
	}
  }
`;
export const getStudentRecommendations = /* GraphQL */ `
  query GetStudentRecommendations($studentId: String, $externalStudentId: String, $schoolId: String, $studentEmail: String, $inputDataSources: SkillsStackInputDataSources!, $outputResourceTypes: [String]!, $subject: String!, $numberOfResources: Int, $usePlacementQuiz: Boolean) {
    getStudentRecommendations(studentId: $studentId, externalStudentId: $externalStudentId, schoolId: $schoolId, studentEmail: $studentEmail, inputDataSources: $inputDataSources, outputResourceTypes: $outputResourceTypes, subject: $subject, numberOfResources: $numberOfResources, usePlacementQuiz: $usePlacementQuiz) {
		intent
		type
		resource {
		course {
		name
		grade
		gradeName
	}
		type
		name
		order
		url
		standards {
		name
		mappingType
	}
		appCourse {
		thirdPartyCourseId
		id
		name
	}
		appTopic {
		thirdPartyTopicId
		id
		name
	}
		appLevel {
		thirdPartyLevelId
		id
		name
	}
		masteryPercentage
		struggleAssistanceFor {
		resourceName
		resourceUrl
	}
		dataSources {
		app {
		signal
		timestamp
	}
		externalTest {
		signal
		timestamp
	}
		internalTest {
		signal
		timestamp
	}
	}
	}
		test {
		courses {
		name
		grade
		gradeName
	}
		name
		app
		url
		status
	}
	}
  }
`;
export const getStandardBasedStudentRecommendations = /* GraphQL */ `
  query GetStandardBasedStudentRecommendations($input: GetStandardBasedStudentRecommendationsInput!) {
    getStandardBasedStudentRecommendations(input: $input) {
		intent
		recommendations {
		standardId
		platformStandardId
		state
		dataSources {
		app {
		masteryPercentage
		signal
		timestamp
		confidenceLevel
	}
		externalTest {
		signal
		timestamp
	}
		internalTest {
		signal
		timestamp
	}
	}
		organizationName
		studentGrade
		confidenceLevel
		order
		academicCourse {
		name
		order
		masteryPercentage
		studentGrade
	}
		cluster {
		name
		platformClusterId
		clusterId
		order
		organizationName
		masteryPercentage
		studentGrade
	}
		domain {
		name
		platformDomainId
		domainId
		order
		organizationName
		masteryPercentage
		studentGrade
	}
		type
	}
	}
  }
`;
export const getStudentStruggleRecommendation = /* GraphQL */ `
  query GetStudentStruggleRecommendation($input: GetStudentStruggleRecommendationInput!) {
    getStudentStruggleRecommendation(input: $input) {
		platformStandardId
		standardId
		description
		reason
	}
  }
`;
export const recommendNextQuestionDifficulty = /* GraphQL */ `
  query RecommendNextQuestionDifficulty($input: RecommendNextQuestionDifficultyInput!) {
    recommendNextQuestionDifficulty(input: $input) {
		difficulty
	}
  }
`;
export const getStudentBalance = /* GraphQL */ `
  query GetStudentBalance($studentId: String!, $startDate: String, $endDate: String) {
    getStudentBalance(studentId: $studentId, startDate: $startDate, endDate: $endDate) {
		amount
		periodGains
	}
  }
`;
export const getStoreItems = /* GraphQL */ `
  query GetStoreItems($referenceGrade: String, $minPrice: Int, $maxPrice: Int, $category: String, $currency: String, $learningAppName: String) {
    getStoreItems(referenceGrade: $referenceGrade, minPrice: $minPrice, maxPrice: $maxPrice, category: $category, currency: $currency, learningAppName: $learningAppName) {
		id
		name
		category
		price
		minGrade
		maxGrade
		imageUrl
		bundleSize
	}
  }
`;
export const getRewardBalance = /* GraphQL */ `
  query GetRewardBalance($studentId: String!) {
    getRewardBalance(studentId: $studentId) {
		cardNumber
		expirationDate
		currentBalance
		cardStatus
	}
  }
`;
export const getRewardTransactions = /* GraphQL */ `
  query GetRewardTransactions($studentId: String!, $page: Int, $pageSize: Int) {
    getRewardTransactions(studentId: $studentId, page: $page, pageSize: $pageSize) {
		itemsCount
		items {
		description
		message
		type
		value
		status
		transactionDate
		chargeDate
	}
		cardStatus
	}
  }
`;
export const getRewards = /* GraphQL */ `
  query GetRewards($studentId: String, $rewardCategory: String, $isActive: Boolean) {
    getRewards(studentId: $studentId, rewardCategory: $rewardCategory, isActive: $isActive) {
		id
		name
		rewardTypes {
		id
		name
		description
		parameters
		displayText
		rewards {
		id
		parameters
	}
	}
	}
  }
`;
export const getRewardConfigurations = /* GraphQL */ `
  query GetRewardConfigurations($input: GetRewardConfigurationsInput!) {
    getRewardConfigurations(input: $input) {
		assetRewards {
		eventType
		rewardFrequencyType
		asset
		assetStatus
		assetCategory
		amount
		program
		programKey
		checkCourse
		rewardsGroupKey
		expectedEventData
	}
		currencyRewards {
		eventType
		rewardFrequencyType
		currency
		currencyKey
		amount
		program
		programKey
		checkCourse
		rewardsGroupKey
		expectedEventData
		rewardCalculationConfig
	}
	}
  }
`;
export const getStudentCashTransactions = /* GraphQL */ `
  query GetStudentCashTransactions($studentId: ID!, $page: Int, $pageSize: Int) {
    getStudentCashTransactions(studentId: $studentId, page: $page, pageSize: $pageSize) {
		items {
		createdUserName
		description
		amount
		createdOn
	}
		itemsCount
	}
  }
`;
export const getStudentOrders = /* GraphQL */ `
  query GetStudentOrders($input: GetStudentOrdersInput!) {
    getStudentOrders(input: $input) {
		studentOrderId
		status
		createdOn
		lastModifiedOn
		assets {
		assetId
		assetName
		assetStoreItemId
		amount
		price
		currencyKey
		currency
	}
		shippingDetails {
		fullName
		addressLine1
		addressLine2
		phoneNumber
		city
		zip
		state
	}
	}
  }
`;
export const getStudentStreaks = /* GraphQL */ `
  query GetStudentStreaks($studentId: String!, $kind: String, $minDate: String, $maxDate: String) {
    getStudentStreaks(studentId: $studentId, kind: $kind, minDate: $minDate, maxDate: $maxDate) {
		id
		studentId
		isCurrent
		startDate
		endDate
		streakLength
		bankedDaysOff
		totalDaysOffTaken
		totalDaysPaused
		days {
		daysOff
		pausedDays
	}
	}
  }
`;
export const getStudentBalances = /* GraphQL */ `
  query GetStudentBalances($input: GetStudentBalancesInput!) {
    getStudentBalances(input: $input) {
		balancesByCurrency {
		currency
		amount
		periodGains
	}
	}
  }
`;
export const getStudentCurrencyTransactions = /* GraphQL */ `
  query GetStudentCurrencyTransactions($input: GetStudentCurrencyTransactionsInput!) {
    getStudentCurrencyTransactions(input: $input) {
		currencyTransactions {
		amount
		currencyId
		transactionDetails
	}
	}
  }
`;
export const getSkillsStackV2 = /* GraphQL */ `
  query GetSkillsStackV2($studentId: String!, $subject: String!, $startGrade: String, $endGrade: String, $inputDataSources: SkillsStackInputDataSources!, $outputResourceType: String!, $resourceTypes: [StandardResourceMappingType]) {
    getSkillsStackV2(studentId: $studentId, subject: $subject, startGrade: $startGrade, endGrade: $endGrade, inputDataSources: $inputDataSources, outputResourceType: $outputResourceType, resourceTypes: $resourceTypes) {
		stack {
		name
		grade
		order
		overallLearnedPercentage
		courses {
		name
		order
		state
		overallLearnedPercentage
		resources {
		id
		masteryPercentage
		name
		order
		state
		tags
		type
		url
		standards {
		name
		mappingType
	}
		appCourse {
		id
		name
	}
		appTopic {
		id
		name
	}
		appLevel {
		id
		name
	}
		dataSources {
		app {
		signal
		timestamp
	}
		externalTest {
		signal
		timestamp
	}
		internalTest {
		signal
		timestamp
	}
	}
	}
	}
	}
	}
  }
`;
export const getStandardBasedSkillsStack = /* GraphQL */ `
  query GetStandardBasedSkillsStack($input: GetStandardBasedSkillsStackInput!) {
    getStandardBasedSkillsStack(input: $input) {
		grades {
		name
		grade
		order
		academicCourses {
		name
		order
		masteryPercentage
		studentGrade
		domains {
		name
		domainId
		platformDomainId
		order
		organizationName
		masteryPercentage
		studentGrade
		clusters {
		name
		clusterId
		platformClusterId
		order
		organizationName
		masteryPercentage
		studentGrade
		standards {
		standardId
		standardDescription
		platformStandardId
		order
		state
		dataSources {
		app {
		masteryPercentage
		signal
		timestamp
		confidenceLevel
	}
		externalTest {
		signal
		timestamp
	}
		internalTest {
		signal
		timestamp
	}
	}
		organizationName
		studentGrade
		confidenceLevel
	}
	}
	}
	}
	}
	}
  }
`;
export const teachTapValidateVoucher = /* GraphQL */ `
  query TeachTapValidateVoucher($input: TeachTapValidateVoucherInput!) {
    teachTapValidateVoucher(input: $input) {
		success
		message
		metadata {
		validity
	}
	}
  }
`;
export const teachTapGetLearningContentItemById = /* GraphQL */ `
  query TeachTapGetLearningContentItemById($id: String!) {
    teachTapGetLearningContentItemById(id: $id) {
		contentId
		contentTypeId
		contentTypeName
		content
		subjectId
		courseId
		unitId
		topicId
		levelId
		difficulty
		totalLikes
		totalViews
		totalComments
		totalBookmarks
		totalShares
		totalReports
		isLikedByUser
		isSharedByUser
		isBookmarkedByUser
		isCommentedByUser
	}
  }
`;
export const teachTapGetUserProgress = /* GraphQL */ `
  query TeachTapGetUserProgress($input: TeachTapGetUserProgressInput!) {
    teachTapGetUserProgress(input: $input) {
		course {
		id
		currentUnitId
		units {
		id
		studentGrade
		currentTopicId
		topics {
		id
		studentGrade
		nextGradeProgress
	}
	}
	}
		userProgress {
		totalAwardedPoints
		totalCorrectQuestions
	}
	}
  }
`;
export const teachTapGetCurriculum = /* GraphQL */ `
  query TeachTapGetCurriculum {
    teachTapGetCurriculum {
		subjects {
		id
		name
		image
		courses {
		id
		name
		grade {
		grade
		name
	}
		mainImageUrl
		thumbnailUrl
		learningOrder
		hasTestPrep
		hasLearnMode
		units {
		id
		name
		learningOrder
		status
		hasTestPrep
		hasLearnMode
		category
		topics {
		id
		name
		learningOrder
		estimatedTimeToCompletion {
		min
		max
	}
	}
	}
	}
	}
	}
  }
`;
export const getTeachTapUserSettings = /* GraphQL */ `
  query GetTeachTapUserSettings {
    getTeachTapUserSettings {
		userId
		goal
		examType
		grade
		emailOptIn
		examDate
		currentScore
		targetScore
		programType
		enrolledAt
		timezone
	}
  }
`;
export const teachTapGetTestScorePercentile = /* GraphQL */ `
  query TeachTapGetTestScorePercentile($input: TeachTapGetTestScorePercentileInput!) {
    teachTapGetTestScorePercentile(input: $input) {
		percentile
		percentileExtended
	}
  }
`;
export const teachTapGetUserEnrolledCourses = /* GraphQL */ `
  query TeachTapGetUserEnrolledCourses {
    teachTapGetUserEnrolledCourses {
		courseId
		enrolledOn
		goal
	}
  }
`;
export const teachTapGetCoursesEnrollmentCount = /* GraphQL */ `
  query TeachTapGetCoursesEnrollmentCount {
    teachTapGetCoursesEnrollmentCount {
		courseId
		enrollmentCount
	}
  }
`;
export const teachTapGetSATUnitTestsHistory = /* GraphQL */ `
  query TeachTapGetSATUnitTestsHistory($input: TeachTapGetUnitTestsHistoryInput!) {
    teachTapGetSATUnitTestsHistory(input: $input) {
		courseId
		units {
		id
		totalTestsTaken
		latestScore
		totalScore
		bestScore
	}
	}
  }
`;
export const teachTapGetUnitTestsHistory = /* GraphQL */ `
  query TeachTapGetUnitTestsHistory($input: TeachTapGetUnitTestsHistoryInput!) {
    teachTapGetUnitTestsHistory(input: $input) {
		courseId
		units {
		id
		totalAttempts
		bestAttempt {
		grade
		submittedAt
	}
		recentAttempts {
		grade
		submittedAt
	}
		targetedPractice {
		isAvailable
		testId
		levelIds
		topicIds
		progress
	}
	}
	}
  }
`;
export const teachTapGetGeneratorExternalUrl = /* GraphQL */ `
  query TeachTapGetGeneratorExternalUrl($input: TeachTapGetGeneratorExternalUrlInput!) {
    teachTapGetGeneratorExternalUrl(input: $input) {
		externalUrl
	}
  }
`;
export const teachTapGetInteractedLearningContentItems = /* GraphQL */ `
  query TeachTapGetInteractedLearningContentItems($input: TeachTapInteractedLearningContentItemsInput!) {
    teachTapGetInteractedLearningContentItems(input: $input) {
		items {
		contentId
		contentTypeId
		contentTypeName
		content
		subjectId
		courseId
		unitId
		topicId
		levelId
		difficulty
		totalLikes
		totalViews
		totalComments
		totalBookmarks
		totalShares
		totalReports
		isLikedByUser
		isSharedByUser
		isBookmarkedByUser
		isCommentedByUser
	}
		totalCount
	}
  }
`;
export const teachTapGetSATProgramDetails = /* GraphQL */ `
  query TeachTapGetSATProgramDetails($input: TeachTapGetSATProgramDetailsInput!) {
    teachTapGetSATProgramDetails(input: $input) {
		programName
		programDuration
		goalTitle
		studyCommitmentTitle
		studyCommitmentHours
		studyCommitmentPeriod
		deadlineToEnroll
		enrolledAt
		programType
	}
  }
`;
export const teachTapGetUserSATProgramDetails = /* GraphQL */ `
  query TeachTapGetUserSATProgramDetails {
    teachTapGetUserSATProgramDetails {
		details {
		programName
		programType
		durationLength
		durationPeriod
		enrolledAt
		examDate
		timeSpentMinutes
		targetTimeCommitmentHours
		targetTimeCommitmentPeriod
		currentScore
		targetScore
		currentDuration
		currentDurationStartDate
		currentDurationEndDate
		readingWritingScore
		mathScore
		hasTakenTest
		freePointsOffered
	}
		accessDetails {
		accessType
		expiresAt
		purchaseSource
	}
	}
  }
`;
export const teachTapGetConfigurations = /* GraphQL */ `
  query TeachTapGetConfigurations {
    teachTapGetConfigurations {
		configurations {
		name
		key
		value
	}
	}
  }
`;
export const teachTapGetUserFlags = /* GraphQL */ `
  query TeachTapGetUserFlags($input: TeachTapGetUserFlagsInput!) {
    teachTapGetUserFlags(input: $input) {
		flags {
		key
		value
		metadata
	}
	}
  }
`;
export const teachTapGetUserStats = /* GraphQL */ `
  query TeachTapGetUserStats {
    teachTapGetUserStats {
		totalAwardedPoints
		totalCorrectQuestions
	}
  }
`;
export const teachTapGetAPProgramDetails = /* GraphQL */ `
  query TeachTapGetAPProgramDetails($input: TeachTapGetAPProgramDetailsInput!) {
    teachTapGetAPProgramDetails(input: $input) {
		courseDetails {
		name
		targetGrade
		examDate
		studyCommitmentHours
	}
		totalStudyCommitmentHours
	}
  }
`;
export const teachTapGetUserAccess = /* GraphQL */ `
  query TeachTapGetUserAccess {
    teachTapGetUserAccess {
		accessibleCourses {
		courseId
	}
		hasUltimateAccess
		hasSATPrepAccess
	}
  }
`;
export const teachTapGetUserAPProgramDetails = /* GraphQL */ `
  query TeachTapGetUserAPProgramDetails {
    teachTapGetUserAPProgramDetails {
		details {
		enrolledAt
		courses {
		id
		examDate
		targetGrade
		currentDuration
		currentDurationStartDate
		currentDurationEndDate
		timeSpentMinutes
		targetTimeCommitmentHours
		targetTimeCommitmentPeriod
	}
	}
		accessDetails {
		accessType
		expiresAt
		purchaseSource
	}
	}
  }
`;
export const teachTapGetMaxGrade = /* GraphQL */ `
  query TeachTapGetMaxGrade($input: TeachTapGetMaxGradeInput!) {
    teachTapGetMaxGrade(input: $input) {
		courseId
		topics {
		id
		maxGrade
	}
	}
  }
`;
export const teachTapCheckUsernameAvailability = /* GraphQL */ `
  query TeachTapCheckUsernameAvailability($input: TeachTapCheckUsernameAvailabilityInput!) {
    teachTapCheckUsernameAvailability(input: $input) {
		isAvailable
		isAppropriate
	}
  }
`;
export const teachTapGetLeaderboard = /* GraphQL */ `
  query TeachTapGetLeaderboard {
    teachTapGetLeaderboard {
		leaderboard {
		topUsers {
		id
		username
		points
		rank
		profileImageUrl
	}
		userRank {
		id
		username
		points
		rank
		profileImageUrl
	}
		adjacentUsers {
		id
		username
		points
		rank
		profileImageUrl
	}
		totalUsers
	}
	}
  }
`;
export const getGuides = /* GraphQL */ `
  query GetGuides {
    getGuides {
		id
		guideName
		alphaLearningLevels
	}
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($studentId: String, $platformStudentId: String, $email: String, $platformSchoolId: String, $schoolStudentId: String, $username: String, $includeInactiveEnrollments: Boolean) {
    getStudent(studentId: $studentId, platformStudentId: $platformStudentId, email: $email, platformSchoolId: $platformSchoolId, schoolStudentId: $schoolStudentId, username: $username, includeInactiveEnrollments: $includeInactiveEnrollments) {
		user_id
		first_name
		last_name
		email
		role_name
		student_id
		campus_id
		alpha_student_id
		external_student_id
		alpha_learning_level
		learning_level
		date_of_birth
		preferred_name
		map_grade_level
		gender
		is_active
		profile_image
		cover_image
		school_id
		school_name
		program_enrollments {
		programEnrollmentId
		programId
		programKey
		startDate
		endDate
		startCourseName
		endCourseName
		subjectName
		isUpdateAllowed
		purchaseSource
	}
		placement_grades {
		grade
		gradeId
		gradeName
		subject
		subjectId
	}
	}
  }
`;
export const getStudentFriendList = /* GraphQL */ `
  query GetStudentFriendList($studentId: String!, $startIndex: Int, $limit: Int) {
    getStudentFriendList(studentId: $studentId, startIndex: $startIndex, limit: $limit) {
		user_id
		first_name
		last_name
		email
		role_name
		student_id
		campus_id
		alpha_student_id
		external_student_id
		alpha_learning_level
		learning_level
		date_of_birth
		preferred_name
		map_grade_level
		gender
		is_active
		profile_image
		cover_image
		school_id
		school_name
	}
  }
`;
export const getStudentUser = /* GraphQL */ `
  query GetStudentUser($userId: String!) {
    getStudentUser(userId: $userId) {
		user_id
		first_name
		last_name
		email
		role_name
		student_id
		campus_id
		alpha_student_id
		external_student_id
		alpha_learning_level
		learning_level
		date_of_birth
		preferred_name
		map_grade_level
		gender
		is_active
		profile_image
		cover_image
		school_id
		school_name
	}
  }
`;
export const getStudentProfileAttributeTypes = /* GraphQL */ `
  query GetStudentProfileAttributeTypes($attributeTypes: [String!]!) {
    getStudentProfileAttributeTypes(attributeTypes: $attributeTypes) {
		attributeType
		attributes {
		id
		value
	}
	}
  }
`;
export const getStudentProfileAttributes = /* GraphQL */ `
  query GetStudentProfileAttributes($studentId: ID!, $attributeTypes: [String!]!) {
    getStudentProfileAttributes(studentId: $studentId, attributeTypes: $attributeTypes) {
		attributeType
		attributes {
		id
		value
	}
	}
  }
`;
export const getStudentWorksmartData = /* GraphQL */ `
  query GetStudentWorksmartData {
    getStudentWorksmartData {
		userId
		teamId
		companyId
	}
  }
`;
export const getUserDetails = /* GraphQL */ `
  query GetUserDetails($userId: String, $schoolStudentId: String, $platformSchoolId: String, $email: String) {
    getUserDetails(userId: $userId, schoolStudentId: $schoolStudentId, platformSchoolId: $platformSchoolId, email: $email) {
		id
		firstName
		lastName
		preferredName
		email
		roleName
		roles
		locale
		profileImageURL
		coverImageURL
		acceptedTerms
		permissions
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
		agents {
		id
		firstName
		lastName
		email
		preferredName
		roles
		platformStudentId
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
	}
		platformStudentId
		referredUserIds
		emailOptIn
		isAnonymous
	}
  }
`;
export const getUserDetailsByEmail = /* GraphQL */ `
  query GetUserDetailsByEmail($email: String!) {
    getUserDetailsByEmail(email: $email) {
		id
		firstName
		lastName
		preferredName
		email
		roleName
		roles
		locale
		profileImageURL
		coverImageURL
		acceptedTerms
		permissions
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
		agents {
		id
		firstName
		lastName
		email
		preferredName
		roles
		platformStudentId
		userEnrollments {
		school
		role
		beginDate
		endDate
		studentId
		username
		referredByUserId
		verified
	}
	}
		platformStudentId
		referredUserIds
		emailOptIn
		isAnonymous
	}
  }
`;
export const getStudentThirdPartyAppCredentials = /* GraphQL */ `
  query GetStudentThirdPartyAppCredentials($appName: String!) {
    getStudentThirdPartyAppCredentials(appName: $appName) {
		appName
		username
		password
	}
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools {
    listSchools {
		id
		name
		schoolKey
		schoolIcon
	}
  }
`;
export const listStudentUsers = /* GraphQL */ `
  query ListStudentUsers($limit: Int, $offset: Int) {
    listStudentUsers(limit: $limit, offset: $offset) {
		user_id
		first_name
		last_name
		email
		role_name
		student_id
		campus_id
		alpha_student_id
		external_student_id
		alpha_learning_level
		learning_level
		date_of_birth
		preferred_name
		map_grade_level
		gender
		is_active
		profile_image
		cover_image
		school_id
		school_name
	}
  }
`;
export const listPermissions = /* GraphQL */ `
  query ListPermissions {
    listPermissions {
		id
		permissionKey
		isPermissionOn
		createdOn
		lastModifiedOn
	}
  }
`;
export const getVoucherDetails = /* GraphQL */ `
  query GetVoucherDetails($voucherCode: String!) {
    getVoucherDetails(voucherCode: $voucherCode) {
		redemptionDate
		program
		type
		purchaseSource
	}
  }
`;
export const checkUsernameAvailability = /* GraphQL */ `
  query CheckUsernameAvailability($input: CheckUsernameAvailabilityInput!) {
    checkUsernameAvailability(input: $input) {
		isAvailable
		isAppropriate
	}
  }
`;
export const getExtendedStudentAttributes = /* GraphQL */ `
  query GetExtendedStudentAttributes($input: GetExtendedStudentAttributesInput!) {
    getExtendedStudentAttributes(input: $input) {
		attributes {
		attributeKey
		attributeValue
	}
	}
  }
`;
export const checkExtendedStudentAttributeAvailability = /* GraphQL */ `
  query CheckExtendedStudentAttributeAvailability($input: CheckExtendedStudentAttributeAvailabilityInput!) {
    checkExtendedStudentAttributeAvailability(input: $input) {
		isAvailable
	}
  }
`;